import { Reducer } from 'redux';
import {
  ActionTypes,
  GET_VALIDATIONS_REQUEST, GET_VALIDATIONS_SUCCESS, GET_VALIDATIONS_FAILURE,
  GET_VALIDATION_REQUEST, GET_VALIDATION_SUCCESS, GET_VALIDATION_FAILURE,
  ADD_VALIDATION_REQUEST, ADD_VALIDATION_SUCCESS, ADD_VALIDATION_FAILURE,
  EDIT_VALIDATION_REQUEST, EDIT_VALIDATION_SUCCESS, EDIT_VALIDATION_FAILURE,
  DELETE_VALIDATION_REQUEST, DELETE_VALIDATION_SUCCESS, DELETE_VALIDATION_FAILURE
} from '../actions/validations';
import { Validation } from 'entities/validation';

interface State {
  validations: Validation[];
  validation: Validation | {};
  loading: boolean;
  error: string | null;
  addValidationLoading: boolean;
  editValidationLoading: boolean;
  deleteValidationLoading: boolean;
}

const initialState: State = {
  validations: [],
  validation: {},
  loading: false,
  addValidationLoading: false,
  editValidationLoading: false,
  deleteValidationLoading: false,
  error: null,
};

const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case GET_VALIDATIONS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_VALIDATIONS_SUCCESS:
      return { ...state, validations: action.payload, loading: false, error: null };
    case GET_VALIDATIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case GET_VALIDATION_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_VALIDATION_SUCCESS:
      return { ...state, validation: action.payload, loading: false, error: null };
    case GET_VALIDATION_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ADD_VALIDATION_REQUEST:
      return { ...state, addValidationLoading: true };
    case ADD_VALIDATION_SUCCESS:
      return { ...state, addValidationLoading: false };
    case ADD_VALIDATION_FAILURE:
      return { ...state, addValidationLoading: false };
    case EDIT_VALIDATION_REQUEST:
      return { ...state, editValidationLoading: true };
    case EDIT_VALIDATION_SUCCESS:
      return { ...state, editValidationLoading: false };
    case EDIT_VALIDATION_FAILURE:
      return { ...state, editValidationLoading: false };
    case DELETE_VALIDATION_REQUEST:
      return { ...state, deleteValidationLoading: true };
    case DELETE_VALIDATION_SUCCESS:
      return { ...state, deleteValidationLoading: false };
    case DELETE_VALIDATION_FAILURE:
      return { ...state, deleteValidationLoading: false };
    default:
      return state;
  }
};

export default reducer;
