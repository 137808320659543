// @flow
import * as React from 'react';
import styles from './style.module.scss';

type Props = {
    icon?: React.Element<any>,
    children?: React.Element<any> | string,
    reversed?: boolean,
    secondary?: boolean,
    red?: boolean,
    grey?: boolean,
    responsive?: boolean,
    hoverable?: boolean,
    alwaysShowIcon?: boolean,
    alwaysSmall?: boolean,
    onClick?: (event: SyntheticEvent<>) => void,
    svgWidth: number,
    svgHeight: number,
    extraClassName?: string,
    disabled?: boolean,
    style?: any,
}

/**
 * Utility function to calculate the class name associated with the current button
 *
 * @param reversed
 * @param extraClassName
 * @return {string}
 */
const computeClassName = (reversed?: boolean, secondary?: boolean, red?: boolean, grey?: boolean, responsive?: boolean, hoverable?: boolean, alwaysSmall?: boolean, extraClassName?: string, disabled?: boolean): string => {

    const classes: Array<string> = [styles.button];

    if (alwaysSmall) {
        classes.push(styles.button_small);
    }

    if (responsive) {
        classes.push(styles.button_responsive);
    }

    if (!disabled) {
        if (hoverable) {
            classes.push(styles.button_hoverable);
        }

        if(reversed) {
            classes.push(styles.reversed);
        }
    } else {
        classes.push(styles.disabled);
    }

    if (secondary) {
        classes.push(styles.secondary);
    }

    if (red) {
        classes.push(styles.red);
    }

    if (grey) {
        classes.push(styles.grey);
    }

    if(extraClassName) {
        classes.push(extraClassName);
    }

    return classes.join(' ');
};

/**
 * This component will be the classic blue button/white button with an icon. For now I don't know if the asset are
 * going to be SVG of standard image. I will assume SVG and correct later if wrong
 *
 * @param props
 */
const Button = (props: Props) => {

    const dynamicStyle = {
        width: props.svgWidth,
        height: props.svgHeight
    };

    const className = computeClassName(
        props.reversed,
        props.secondary,
        props.red,
        props.grey,
        props.responsive,
        props.hoverable,
        props.alwaysSmall,
        props.extraClassName,
        props.disabled,
    );

    let iconStyle = styles.icon_holder;
    if (props.alwaysShowIcon === false) {
        iconStyle = `${iconStyle} ${styles.hidden_if_big}`;
    }

    return (
      <button className={className} onClick={!props.disabled ? props.onClick : () => {}} style={props.style}>
          {props.icon && (
              <span className={iconStyle} style={dynamicStyle}>
                  {props.icon}
              </span>
          )}
          <span className={styles.text_holder}>
              {props.children}
          </span>
      </button>
    );

};

Button.defaultProps = {
    svgWidth: 15,
    svgHeight: 15,
    responsive: true,
    hoverable: true,
    alwaysShowIcon: true,
    alwaysSmall: false,
    onClick: event => {},
    enabled: true,
};

export default Button;
