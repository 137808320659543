import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/browser';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  eventId: string | undefined;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, eventId: undefined };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    const devMode = process.env.NODE_ENV === 'development';

    if (this.state.hasError && !devMode) {
      //render fallback UI
      return (
        <Box
          bgcolor="primary.main"
          display="flex"
          width="100vw"
          height="100vh"
          justifyContent="center"
          alignItems="center"
        >
          <Card>
            <CardContent>
              <Typography>Oops, something went wrong!</Typography>
              <Typography>
                We've been notified, but you can send more information below.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                onClick={() =>
                  Sentry.showReportDialog({ eventId: this.state.eventId })
                }
              >
                Send feedback
              </Button>
            </CardActions>
          </Card>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
