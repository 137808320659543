import { Reducer } from 'redux';
import {
  ActionTypes,
  GET_LOCATIONS_REQUEST, GET_LOCATIONS_SUCCESS, GET_LOCATIONS_FAILURE,
} from '../actions/location';


export interface Location {
  city: any;
  uuid: string;
  country: string;
  countryLong: string;
  province: string;
}

interface State {
  locations: Location[];
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  locations: [],
  loading: false,
  error: null,
};

const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCATIONS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_LOCATIONS_SUCCESS:
      return { ...state, locations: action.payload, loading: false, error: null };
    case GET_LOCATIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default reducer;
