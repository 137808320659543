import React, { createContext, useContext } from 'react';
import { Flag, UseFlagsOptions, UseFlags } from './types';

interface FlagsProviderProps {
  value: Flag[];
  children: React.ReactNode;
}

interface FlagsConsumerProps {
  authorizedFlags: string[];
  mustMatchAll?: boolean;
  children: React.ReactNode;
  renderOff?: (flags: string[]) => React.ReactNode;
}

const FlagsContext = createContext<Flag[]>([]);

const FlagsProvider: React.FC<FlagsProviderProps> = ({ value, children }) => {
  return (
    <FlagsContext.Provider value={value}>{children}</FlagsContext.Provider>
  );
};

export const useFlags = ({
  authorizedFlags,
  mustMatchAll,
}: UseFlagsOptions): UseFlags => {
  const flags = useContext(FlagsContext);
  const matchedFlags = flags
    .filter(flag => flag.isActive && authorizedFlags.includes(flag.name))
    .map(flag => flag.name);

  if (mustMatchAll) {
    return {
      matchedFlags,
      renderContent: matchedFlags.length === authorizedFlags.length,
    };
  }

  return {
    matchedFlags,
    renderContent: matchedFlags.length > 0,
  };
};

export const Flags: React.FC<FlagsConsumerProps> = ({
  authorizedFlags,
  mustMatchAll,
  children,
  renderOff,
}) => {
  const { matchedFlags, renderContent } = useFlags({
    authorizedFlags,
    mustMatchAll,
  });

  if (renderContent) {
    return <>{children}</>;
  }

  if (renderOff) {
    return <>{renderOff(matchedFlags)}</>;
  }

  return null;
};

export default FlagsProvider;
