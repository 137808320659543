/* eslint-disable import/no-anonymous-default-export */
export default {
  autocomplete: {
    start_typing: 'Start typing...',
    individual_text: 'Type a city, state or country',
    lev_text: 'Type a city, state or country',
    no_options: 'No options',
  },
  featureNotInPlan:'This feature is not available in your plan.',
  acknowledgement: "I acknowledge and agree to Valital's",
  agreement: 'I agree to',
  announcement: {
    '2fa': {
      title: 'Keep your account secure with <br /> Two-step Verification',
      description: `Two-step Verification allows you to connect a trusted phone number to your Valital account, 
      and use it to confirm your identity when signing in.`,
      note: 'Your organisation requires to activate two-step verification',
      action_main: 'Enable Two-step Verification',
      action_secondary: 'No thank you',
    },
  },
  lev_not_in_plan: {
    title: 'Feature not available',
    description_1: 'This feature is not available in your plan.',
    description_2: 'Please contact Valital to activate it.',
  },
  change_plan_costs_note:
    '<small>Note: you will be charged a total of <strong>{{costs}}</strong> for exhauting your current plan too soon.</small>',
  change_plan_invoice_will_be_sent:
    'An invoice will be sent in the next 24 hours.',
  back_to_home: 'Back to Home',
  continue: 'Continue',
  free_trial: "You're on the free trial: {{remaining}} days remaining.",
  plan_expired: 'Your plan is expired.',
  mark_all_as_read: 'Mark All as Read',
  last_validated_on: 'Last update on {{date}}',
  live_update_description:
    'LiveUpdate will monitor online media daily and will notify you of new information.',
  include_twitter_description:
    'Enable Twitter to see potential usernames and flagged tweets associated to this individual.',
  extend_report_description:
    'Enable this feature to include misconduct results that fall below our ID matching threshold. Expect more false positives as a result.',
  not_yet_validated: 'Not yet validated',
  new_version_available: "There's a new version available",
  update_now: 'Update now',
  detail_view: 'Detail View',
  grid_view: 'Grid View',
  on_date: 'on {{date}}',
  search_placeholder: 'Search by name',
  search_show_all: 'Show all results for {{value}}',
  something_went_wrong: 'Oops, something went wrong! Please try again later...',
  organization_submit_succes: 'Your validation for {{name}} has been submitted',
  thank_you_for_your_input: 'Thank you for your input!',
  view_all: 'View all →',
  view_all_with_count: 'View all ({{count}}) →',
  notifications: {
    a_referral: 'a referral',
    creator_added_subject: '{{creator_name}} added {{subject_name}}',
    creator_changed_status:
      "{{creator_name}} changed {{subject_name}}'s status",
    from_to: 'from "{{previous}}" to "{{next}}"',
    has_been_validated: 'has been validated',
    has_been_declined: 'has been declined',
    in_subject_behaviour: "in {{subject_name}}'s behaviour",
    on_date: ' on {{date}}.',
    one_of: 'One of',
    significant_change_noted: 'significant change noted',
    subject_added: '{{subject_name}} added',
    subject_changed_status: '{{subject_name}} changed their invitation status',
    subject_deleted: '{{subject_name}} deleted',
    subject_referrals: "{{subject_name}}'s referrals",
    subject_removed: '{{subject_name}} removed',
    subject_updated: '{{subject_name}} updated',
    their_account: 'their account',
    to_the_company: "to the company's list of {{user_type}}s",
    human_curator_updated_report: 'The report of {{subject_name}} is available',
    refused: 'Declined',
    accepted: 'Accepted',
    pending: 'Pending',
    load_more: 'Load More',
    active: 'Active',
    inactive: 'Inactive',
  },
  pages: {
    activate: {
      almost_there: "you're almost there",
      finish_registering: 'Finish registering and start using Valital',
      stand_out: 'Make your company stand out by adding a logo (optional)',
      complete_registration: 'Complete registration',
    },
    billing: {
      add_a_card: 'Add a card',
      back_to_plans: 'Back to plans',
      send_invoices_to: 'We will send invoices to this email',
    },
    business_plans_modal: {
      plans_for_every_org:
        'Whether you’re a small or big business, we have a plan for you.',
    },
    import_csv_form: {
      title: 'Import Individuals',
      description: 'Upload a CSV file to easily add multiple individuals',
    },
    dashboard: {
      delete_talent: 'Delete talent',
      empty_dashboard: {
        title: 'Welcome to Valital!',
        subtitle:
          'Invite your first candidate or employee and receive their online conduct report and references a few minutes after they accept the invite',
        button_text: 'Invite your first talent',
      },
      cancel: {
        title: 'Cancel invitation?',
        body: 'By cancelling the request, {{first_name}} {{last_name}} will be deleted from your company.',
      },
      delete: {
        title: 'Delete {{first_name}}?',
        body: 'Are you sure you want to delete {{first_name}} {{last_name}} from your company?',
      },
      live_validation_not_available: {
        title: "Your plan doesn't support LiveUpdate alerts",
        description:
          'This feature is only available for business plans. Upgrade your plan to get access to the feature!',
      },
      pending_for_more_than_5_days: 'Pending for more than 5 days',
      columns_to_display: 'Columns to display',
    },
    delegates: {
      title: 'Your delegates',
      deactivate: {
        title: 'Deactivate {{first_name}} {{last_name}}?',
        body: 'Deactivating a user will prevent them from having delegate access to this company.',
      },
      select_user_type: 'Select a permission',
    },
    export_to_csv: 'Export to csv',
    download: 'Download',
    login: {
      '2fa': {
        title: 'Enter the security code to continue',
        new_device:
          'This appears to be a new device, browser or location. Enter the security code you received from a text message or from your authenticator app.',
        remember_me: 'Remember this device for {{ days }} days ',
        issues: 'Having issues? Try another way',
        issues_details: 'Contact us',
      },
      title: 'Welcome to Valital',
      forgot_password: 'Forgot your password?',
      start_free_plan: 'Start for free',
      invalid_code: 'Invalid code. Please try again.',
      account_no_access: 'Account does not have access',
      attemps_excceeded:
        'Password attempts exceeded. Please try again in 2 minutes.',
      temporary_password_expired: 'Your temporary password has expired. ',
      temp_pass_click_here: 'Click here',
      temp_pass_reset: ' to reset it.',
      temp_pass_sent: 'Your temporary password has been sent.',
      mfa_code_resent: 'A new code has been sent.',
    },
    verify: {
      title: 'Verify this Email',
    },
    profile: {
      '2fa': {
        title: 'Please enter your mobile number',
        subtitle:
          'Keep your account extra secure with a second verification step',
        subtitle_admin:
          "Keep your organization's account extra secure with a second verification step",
        enabled: 'Enabled with phone number {{ phone_number }}',
        insert_code: 'Insert the code we sent you by SMS',
        change_number: 'Change number',
      },
      leave_blank:
        "Leave this blank if you don't plan on changing the password",
      password_changed: 'Password changed successfully',
      delete: {
        delegate: {
          title: 'Account deletion',
          body1: 'Please email',
          body2: 'to proceed with your account deletion.',
        },
      },
    },
    referrals: {
      score_1: "Reference's email is not a Company domain",
      score_2: '{{company}} not found in Canadian federal databases',
      score_3: "Reference's email may not belong to {{company}}",
      worked_as: 'Worked as a ',
      work_period: 'Work period {{period}}',
      reference_status: 'Reference status{{updated}}',
      details: 'Details',
      reference_name: 'Reference name',
      reference_email: 'Reference email',
      request_reference: {
        text: 'Your talent has not shared any references with you yet.<br/>Request a reference by clicking on the button above',
        button: 'Request a reference',
      },
    },
    register: {
      intro: {
        title: 'Get started for<1> free </1>in 2 simple steps.',
        free_trial: 'No credit card required',
        card_1: 'Tell us about your company',
        card_2: 'Introduce yourself',
      },

      title_step_1: 'Tell us about your company',
      input_label_step1_1: 'Company name',
      input_label_step1_2: 'Number of employees',

      title_step_2: "Now, let's get to know you",
      input_label_step2_1: 'Business email',

      terms_of_use: "I agree to Valital's <2>terms of use</2>",
      sign_up: 'Sign up',

      success: {
        title: 'Your request has been submitted',
        next: 'Next: Check your email',
      },
    },
    reset_password: {
      title: 'Forgot your password?',
      title_done: 'Thank you!',
      body: 'Enter your email to reset your password',
      body_done:
        'The password reset code has been sent. Please check your inbox.',
    },
    search_results: {
      title: 'All results for {{search}}',
      your_talents: 'Your talents',
      your_talents_SECURITY: 'Individuals',
      not_your_company: 'Not part of your company',
    },
    update_password: {
      body: 'Please enter a new password for your account',
    },
    sso:{
      body:'Please enter your organization domain'
    },
    mfa_request: {
      title: 'Two-Factor Authentication',
      body: 'Enter the one time code you received',
      not_received: "Didn't receive a code?",
      button: 'Verify',
    },
    plans: {
      change_plan: '{{changeType}} a {{newPlanType}} plan?',
      change_plan_final_step:
        'Your change will take effect as soon as you complete this step.',
      no_billing_info: 'No billing information found on file',
      no_billing_info_description:
        'A credit card is required to upgrade your plan',
      update_billing_info: 'Update billing info',
      invalid_plan: 'Invalid plan',
      company_not_registered: "Your company's plan has expired",
      company_not_registered_details:
        'Please contact your administrator to ensure you have an active subscription',
      unable_to_process_payment:
        'We were unable to process your payment for this month',
      update_billing: 'Update billing',
      missing_billing_info:
        "You don't have billing information on file. Add it before your next payment!",
    },
    plans_and_payments: {
      view_details: 'View details',
      current_plan: 'Current plan: {{plan_name}}',
      billing_cycle_ends: 'Your plan renews on: {{date}}',
      next_payment: 'Next payment {{next_payment}}',
      validations_made: 'Validations made',
      validations_remaining: 'validations remaining until',
      manage_subscription: 'Manage subscription',
      your_plan_breakdown: 'Your plan breakdown for this month:',
      purchased_bundles: 'Purchased bundles for this plan',
      available_bundles:
        'Reaching your plan limit? Add a bundle for a one time fee.',
      bundle_types: {
        additional_validations: 'validations',
        additional_users: 'users',
      },
      will_be_added: '{{count}} {{type}} will be added to your subscription.',
      will_be_charged: 'You will be charged as soon as you click "Purchase"',
      were_be_added: '{{count}} {{type}} were added to your subscription.',
      bundle_unavailable: 'Bundle unavailable',
      bundle_unavailable_free_trial:
        'You cannot add bundles with the Free Trial',
      bundle_unavailable_limit:
        'You must be closer to your plan limit to add a bundle',
      you_sure_cancel: 'Are you sure you want to cancel?',
      cancel_description_first:
        'You will lose access to the platform and will be charged',
      cancel_description_second:
        'based on the number of validations that have been used in your account',
      cancel_successful: 'Your subscritpion has been successfully cancelled',
      questions: 'Have questions? View our',
      online_conduct_report: 'Online conduct report',
      unlimited_job_confirmation: 'Unlimited job confirmation',
      users_per_company: 'users per company',
    },
    user_management: {
      teams: {
        type_PRIVATE: 'Private',
        type_SHARED: 'Shared',
        team: 'Team',
        teams: 'Teams',
        type: 'Type',
        users: 'Users',
        allocated_validations: 'Allocated Validations',
        available_validations: 'Available Validations',
        validations_available: '{{validations}} validations available',
        add_team: 'Add Team',
        edit_team: 'Edit Team',
        delete_team: 'Delete Team',
        delete_step1_body: 'Deletion of {{team}} team will move all users to general team. Remaining validations will be added to the account quota. You have the option to move the validations of this team to another or permanently delete them. Type "Delete Team" to confirm team deletion.',
        delete_button_1: 'Delete and move validations',
        delete_button_2: 'Permanently Delete',
        delete_button_3: 'Back',
        delete_button_4: 'Delete',
        private_validations: 'Private Validations',
        private_validations_info: 'Allow team members to submit validations that will not be shared with other team members',
        disabled: 'Disabled',
        enabled: 'Enabled',
        min_vals_err: ' Number of validations must be greater than or equal to 0.'
      },
      users: {
        user: 'User',
        access_type: 'Access Type',
        status: 'Status',
        status_ACTIVE: 'Active',
        status_INACTIVE: 'Inactive',
        add_user: 'Add User',
        edit_user: 'Edit User',
        role_ADMIN: 'Account Admin',
        role_OWNER: 'Account Owner',
        role_LEAD: 'Team Admin',
        role_MEMBER: 'Team Member'
      }
    },
    lev_report: {
      summary_details: {
        box_1:
          'The results below report adverse online information around Environmental, Social and Governance factors as established by common ESG standards and frameworks.',
        box_2:
          'Reports flagged in yellow contain adverse information online related to the corresponding ESG pillar that mentions the designated company.',
        box_3:
          'A green indicator means no ESG-based adverse information is found online by our AI models.',
        ENVIRONMENT: {
          title: 'Environmental',
          description:
            'Results are based on publicly available online information linking an organization to adverse environmental events. A yellow indicator signals unfavourable information based on ESG factors.',
        },
        SOCIAL: {
          title: 'Social',
          description:
            'Results are based on publicly available online information linking an organization to adverse social events. A yellow indicator signals unfavourable information based on ESG factors.',
        },
        GOVERNANCE: {
          title: 'Governance',
          description:
            'Results are based on publicly available online information linking an organization to adverse governance events.  A yellow indicator signals unfavourable information based on ESG factors. ',
        },
        OTHER: {
          title: 'Other',
          description:
            'Adverse media results are flagged here when they fall outside globally recognized ESG guidelines.',
        },
      },
      categories: {
        ENVIRONMENT: 'Environmental',
        SOCIAL: 'Social',
        GOVERNANCE: 'Governance',
        OTHER: 'Other',
      },
    },
    report: {
      verified_estimate: {
        validation_under_review: 'Your most recent validation is under review.',
        ready_shortly: 'It should be ready shortly...',
        ready_monday:
          'The updated report will be available by 1 pm on Monday. We will notify you by email when it’s ready.',
        ready_in_24: `The updated report will be available within {{hours}}h. We will notify you by email when it’s ready.`,
      },
      history: {
        history: 'History',
        no_activities: 'This user has no activities',
        activities: {
          toggled_live_update:
            '{{employment}} was put on LiveUpdate by {{user}}.',
          toggled_live_update_off:
            '{{employment}} was taken off LiveUpdate by {{user}}.',
          added_individual:
            '{{employment}} was added to your company by {{user}}.',
          added_talent:
            '{{employment}} was invited to your company by {{user}}.',
          invitation_accepted:
            '{{employment}} accepted the invitation to your company.',
          invitation_declined:
            '{{employment}} declined the invitation to your company.',
          started_validation: 'A validation was started by {{user}}.',
          started_twitter_validation:
            'A Twitter analysis was started by {{user}}.',
          preliminary_report: 'The report is pending verification.',
          published_validation: "{{name}}'s report has been updated",
          published_validation_twitter: '{{newArticles}} new tweets were found',
          published_validation_twitter_plural:
            '{{newArticles}} new tweets were found',
          from_live_update: ' following a LiveUpdate',
          conduct_changed: 'The individual’s status is:',
          lev_conduct_changed: "The organization's status is:",
          conduct_unchanged: "The individual's report remains unchanged:",
          lev_conduct_unchanged: "The organization's report remains unchanged:",
          sharing_reference_shared:
            '{{employment}} shared a reference from {{reference}} with your company.',
          sharing_reference_unshared:
            '{{employment}} unshared a reference from {{reference}} with your company.',
          confirming_reference_confirmed:
            "A reference from {{reference}} has confirmed {{employment}}'s employment.",
          confirming_reference_declined:
            "A reference from {{reference}} has declined {{employment}}'s employment.",
        },
      },
      strong_id_match: 'Strong ID Match',
      weak_id_match: 'Weak ID Match',
      very_weak_id_match: 'Very Weak ID Match',
      percentage_id: '{{percentage}} %',
      more_than_90: 'More than 90',
      accused: 'Subject of the action',
      victim: 'Not the subject of the action',
      english: 'EN',
      french: 'FR',
      spanish: 'ES',
      portuguese: 'PT',
      undefined: 'Subject of the action not analyzed',
      showing_results: 'Showing {{count}} result',
      showing_results_plural: 'Showing {{count}} results',
      empty_conduct: {
        title: 'Your report is underway!',
        description:
          'Fast is good; accuracy is even better. We know which you value most.',
        lev_description:
          'Good reports come to those who wait...just a little while longer.',
      },
      extended_report: {
        notice:
          'The results below may include articles that fall below our ID matching threshold. Expect more false positives since the extended search has been enabled.',
      },
      languageOptions: {
        english: "English",
        french: "French",
        spanish: "Spanish",
        portuguese: "Portuguese"
      },
      categories: {
        'HIGH RISK': 'High Risk',
        INVESTIGATE: 'Investigate',
        CLEAR: 'Clear',
      },
      filters: {
        category: 'Category',
        filter_articles: 'Filter articles',
        topics: 'Topics',
        general: {
          idMatch: {
            main: 'Identity Match',
            STRONG: {
              main: 'Strong (80%+)',
              sentence: 'Strong Identity Match',
            },
            WEAK: {
              main: 'Weak (61 to 79%)',
              sentence: 'Weak Identity Match',
            },
            VERY_WEAK: {
              main: 'Very Weak (40 to 60%)',
              sentence: 'Very Weak Identity Match',
            },
          },
          language: {
            main: 'Language of the article',
            ENGLISH: {
              main: 'EN',
              sentence: 'English',
            },
            FRENCH: {
              main: 'FR',
              sentence: 'French',
            },
            SPANISH: {
              main: 'ES',
              sentence: 'Spanish'
            },
            PORTUGUESE: {
              main: "PT",
              sentence: 'Portuguese'
            }

          },
          sourceCategory: {
            main: 'Source',
            mainstream_media: {
              sentence: 'Mainstream Media',
            },
            government_agencies: {
              sentence: 'Government Agencies',
            },
            legitimate_online_database: {
              sentence: 'Legitimate online databases',
            },
            education_professional_associations: {
              sentence: 'Education or Professional Associations',
            },
            strong_content: {
              sentence: 'Strong Content Quality',
            },
            user_source: {
              sentence: 'My Sources',
            },
            not_verified: {
              sentence: 'Not Verified',
            }

          },
          articleType: {
            main: 'Article Type',
            LIVE: {
              main: 'Live',
              sentence: 'Live Articles',
            },
            INSTANT: {
              main: 'Instant',
              sentence: 'Instant Articles',
            },
          },
          source: {
            main: 'Source',
            CREDIBLE: {
              main: 'Verified',
              sentence: 'Verified Source',
            },
            NOT_CREDIBLE: {
              main: 'Unverified',
              sentence: 'Unverified Source',
            },
          },
          publishDate: {
            main: 'Publishing date',
            include: 'Include articles with no publishing date',
            selectPresetRange: 'Select',
            range: "Published between {{start}} and {{end}}",
            by: "By",
            from : "from ", 
            to: "to",
            fromStart: "From {{start}}", 
            toEnd: "Until {{end}}",
            startDate: "Published from {{start}}",
            endDate: "Published to {{end}}",
            noDate: "No publishing date",
            yesterday: "Since yesterday",
            custom: "Custom",
            thisMonth: 'This month',
            lastYear: 'Last 12 months',
            last6Months: 'Last 6 months',
            last3Months: 'Last 3 months',
            last2Years: 'Last 2 years',
            last5Years: 'Last 5 years',
            clear: 'Clear',
            apply: 'Apply',
          },
          subject: {
            main: 'Role in the Article',
            TRUE: {
              main: 'Yes',
              sentence: 'Subject Of The Action',
            },
            FALSE: {
              main: 'No',
              sentence: 'Not Subject Of The Action',
            },
            UNDEFINED: {
              main: 'Not analyzed',
              sentence: "Not Analyzed",
            }
          },
          extendReport: {
            main: 'Extended Report',
            TRUE: {
              main: 'Yes',
              sentence: 'Is Extended Report',
            },
            FALSE: {
              main: 'No',
              sentence: 'Is not Extended Report',
            },
          },
        },
        validations: {
          show_archived_only: 'Show archived validations',
          show_not_archived_only: 'Show active validations',
          show_all: 'Show all validations',
          privacy: 'Privacy',
          show_private: 'Show private validations',
          show_public: 'Show public validations',
          team: 'Team'
        },
      },
      theme: {
        ADDICTION: 'Addiction',
        DISCRIMINATION: 'Discrimination',
        FRAUD: 'Fraud / Financial crime',
        HARASSMENT: 'Harassment',
        VIOLENCE: 'Violence',
        NEWS: 'Other News',
        LITIGATION: 'Conflicts / Litigations',
        TWITTERFLAGS: 'Flagged Tweets',
      },
      lev_theme: {
        ENVIRONMENT: 'Environmental',
        SOCIAL: 'Social',
        GOVERNANCE: 'Governance',
        OTHER: 'Other',
      },
      conduct_summary: {
        title: 'Numbers at a glance',
        how_to_read_this_report: 'How to read this report',
        no_articles_found: 'No articles found on {{misconduct}}',
        articles_analyzed:
          ' links identified out of more than {{found}} links analyzed',
      },
      twitter: {
        flagged_on: 'Flagged on',
        title:
          'Activity flagged for misconduct for <2>@{{twitter_screen_name}}</2>',
        tweets_flagged:
          '{{flagged}} out of {{analyzed}} tweets flagged in the latest analysis',
        tweets_flagged_date:
          '{{flagged}} out of {{analyzed}} tweets flagged in the latest analysis ({{start}} - {{end}})',
        no_twitter_account: {
          title:
            'We don’t have a twitter username associated to this individual.',
          subtitle:
            'Link a twitter account below or update the individual’s validation.',
          button: 'Run twitter analysis',
        },
        no_tweets: {
          title:
            "We couldn't find any tweets to analyze on <2>@{{twitter_screen_name}}</2>",
          subtitle:
            'This could be because the account is private or the username does not exist.',
        },
        all_good: {
          title:
            "We've analyzed {{analyzed}} tweets and found no flagged behavior for @{{twitter_screen_name}}",
          zero_retrieved:
            'We found 0 {{tweetsOrRetweets}} for @{{twitter_screen_name}}',
        },
      },
      summary_details: {
        green_title: 'Clear',
        green_description1: `Negative articles mentioning the individual were found online but the individual is not the subject of that negative news`,
        green_description3: `the articles mentioning the individual found online are not related to misconduct.`,
        green_description_SECURITY: `There’s no online information on the individual, 
        or there is no news flagged as a misconduct on the individual, or the individual 
        is mentioned in an article classified as a misconduct in which they are not the subject of the action.`,
        green_description_lev1: `Negative articles mentioning the organization were found online but the organization is not the subject of that negative news`,
        OR: `OR`,
        green_description_lev3: `no negative articles mentioning the organization were found online.`,
        yellow_title: 'Investigate',
        yellow_description1: `Articles containing adverse information on the individual were found online, but either the identity matching is weak (over 60% but less than 80%)`,
        yellow_description3: `the sources of the articles are not verified.`,
        yellow_description_SECURITY: `The individual is the subject of an article related to a misconduct, 
        but either the source of the article is not credible or the ID match is weak (over 60% but less than 80%). You may need to investigate further.`,
        yellow_description_lev: `Articles containing adverse information on the organization were found online, but either the identity matching is weak (over 60% but less than 80%) OR the sources of the articles are not verified.`,
        yellow_description_lev1: `Articles containing adverse information on the organization were found online, but either the identity matching is weak (over 60% but less than 80%)`,
        yellow_description_lev3: `the sources of the articles are not verified.`,
        red_title: 'High Risk',
        red_description: `Articles from a verified source, containing adverse information about the individual were found online. Moreover, the identity matching is strong (80% or more). This information could have an impact on your risk assessment.`,
        red_description_SECURITY: `The individual is the subject of a credible article related to misconduct and the ID Match is strong (80% or more). 
        Assess how this information could impact your organization. `,
        red_description_lev: `Articles from a verified source, containing adverse information on the organization were found online. Moreover, the identity matching is strong (80% or more). This information could have an impact on your risk assessment.`,
        twitter_title: 'Flagged Tweets',
        twitter_description:
          'Tweets and retweets are flagged if the content and context fall within the five  misconduct categories. The use of foul or inappropriate language is also flagged.',
      },
      articles_found: {
        title: 'Information found',
        empty_title: 'All good!',
        empty_description: "We didn't find any article for this section",
        source_is_credible: 'Source credibility validated',
      },
      profile: {
        year_of_birth: 'Year of Birth',
      },
      load_more: 'Load more',
    },
    lev_csv_form: {
      validate_from_csv: 'Validate From CSV',
      heading: 'Your CSV file must have the following columns:',
      subheading: 'You will be able to edit prior to submitting.',
      download_template: 'Download Template',
      downloaded_template: 'Valital-CSV-Template.csv',
      upload_file: 'Upload File',
      fix_errors: 'You must fix all errors before submitting.',
      bullets: {
        organization_name: 'Organization name',
        acronym: 'Acronym (optional)',
        other_name: 'Other name (optional)',
        city: 'City (optional)',
        province: 'Province / State (optional)',
        country: 'Country (optional)',
        industry: 'Industry',
        live_update: 'Live Update (true or false)',
      },
      error: {
        name_missing: 'Missing organization name',
        industry_missing: 'Missing industry',
      },
    },
    csv_form: {
      import_csv: 'Import From CSV',
      validate_from_csv: 'Validate From CSV',
      heading: 'Your CSV file must have the following columns:',
      subheading: 'You will be able to edit prior to submitting.',
      download_template: 'Download Template',
      downloaded_template: 'Valital-CSV-Template.csv',
      upload_file: 'Upload File',
      fix_errors: 'You must fix all errors before submitting.',
      error: {
        first_name_missing: 'Missing first name',
        last_name_missing: 'Missing last name',
        last_name_invalid: 'Last name invalid',
        first_name_invalid: 'First name invalid',
        city_invalid: 'Invalid city',
        location_invalid: 'Invalid location',
        roles_no_company: 'Title with no matching organisation',
        too_many_companies: 'More than 3 organizations',
        too_many_nickname: 'One nickname maximum',
        twitter_name_invalid: 'Twitter handle invalid',
      },
      bullets: {
        first_name: 'First Name',
        last_name: 'Last Name',
        nick_name: 'Nickname (optional)',
        alternative_first_name: 'Alternative First Name (optional)',
        // year_of_birth: 'Year of Birth',
        city: 'City',
        province: 'Province / State',
        country: 'Country',
        year_of_birth: 'Year of birth (optional)',
        company1:
          'Organization 1 (An organization the individual is affiliated with)',
        role1: 'Role 1 (Role at organization 1)',
        company2:
          'Organization 2 (An organization the individual is affiliated with)',
        role2: 'Role 2 (Role at organization 2)',
        company3:
          'Organization 3 (An organization the individual is affiliated with)',
        role3: 'Role 3 (Role at organization 3)',
        twitter: 'Twitter Username (optional)',
        live_update: 'Live Update (true or false)',
        extended_report: 'Extended Search (true or false)',
      },
    },
    lev_validation_form: {
      title: 'Organization to validate',
      fields: {
        organization_name: 'Organization name',
        acronym: 'Acronym (optional)',
        other_name: 'Other name (optional)',
        industry: 'Industry',
        location_empty:
          'Fill in if the company is registered/incorporated in a single jurisdiction, or if you want to optimize your search results.',
      },
      user_suggestions:
        'One or more organizations with this name already exists. Click on one of the names below to update their report.',
      spelling_fix:
        'The following similar organization name is found online. Click on it if you prefer to use it instead of the name you entered.',
    },
    validation_form: {
      spelling_fix: 'The following similar name is found online. Click on it if you prefer to use it instead of the name you entered.',
      last_validated_on: 'Last updated on ',
      nothing_found: 'Nothing found',
      show_more_tweets: 'Show {{count}} more results',
      submit_and_new: 'Submit and new',
      submit_more: 'Submit more',
      submit_and_close: 'Submit validation',
      next: 'Next',
      cancel: 'Cancel',
      previous: 'Previous',
      privacy_PRIVATE: 'Private',
      privacy_PUBLIC: 'Public',
      dont_include_twitter: `Don't include twitter`,
      year_of_birth: 'Year of birth (optional)',
      edit_twitter: {
        title: 'Are you sure you want to change the twitter username?',
        description:
          'Changing the username will delete all tweets flagged from the other account.',
      },
      title: 'Individual to validate',
      description_editing: '',
      user_suggestions:
        'One or more individuals with this name already exists. Click on one of the name below to update their report.',
      add_companies:
        'Organizations the individual is affiliated with (3 maximum) ',
      twitter_username: 'Twitter username',
      add_twitter_username:
        'Include a Twitter username to run a Twitter analysis (optional)',
      search_twitter_user: 'None of the above? Enter a Twitter username',
      select_twitter_user:
        'We found the following Twitter usernames that could belong to this individual. Select one to include Twitter in your report.',
      add_companies_editing:
        'Add or remove companies (a maximum of 3) to modify the results',
      positions_occupied:
        'Here are the organisations and the titles for each that this person occupied',
      helper_text_company: 'Press enter to add another organization',
      helper_text_role: 'Press enter to add another organization & role',
      no_results:
        'Run your first validation by clicking on the button on the top left',
      no_results_mobile:
        'No Validations ! Run your first validation by clicking the button on the bottom.',
      tooltip:
        'Current annual subscription. Totals may include multiple validations of the same individual and archived validations.',
      filters: {
        max_date_error: 'Must be before end date',
        min_date_error: 'Must be after start date',
      },
    },
    individual_search: {
      search_by_city: 'Search Individuals by City',
    },
    validation_privacy: {
      public_info: "This validation will be accessible to everyone in your team",
      private_info_MEMBER: "This validation will be accessible to yourself, your team admin(s), the account admin(s) and the account owner",
      private_info_LEAD: "This validation will be accessible to yourself, the account admin(s) and the account owner",
      private_info_ADMIN: "This validation will be accessible to yourself and the account owner",
      private_info_OWNER: "This validation will only be accessible to yourself"
    },
    csv_validation_usage: {
      validations_remaining: 'You have {{value}} validations remaining through CSV for today.',
      zero_validations_remaining: 'You have no validation remaining through CSV for today. Please submit your validations on a individual basis.',
      limit_exceeded: 'You have exceeded your CSV limit. You must remove {{value}} validations before submitting.'
    }
  },
};
