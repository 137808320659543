// @flow

import React, { useEffect, useMemo } from 'react';
import type { ComponentType } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { NotificationManager } from 'react-notifications';
import { useUser, useRefreshToken, useToken } from 'utils/contexts/app-context';
import { AUTH } from 'router/routes';
import LoadingDots from 'components/loading-dots';

/* eslint-disable */

const requiresAuth: RequireAuth = (WrappedComponent, options) => {
  const AuthenticatedComponent = () => {
    const { authType, redirectTo } = options || {};
    const { t } = useTranslation();

    const refreshToken = useRefreshToken();
    const token = useToken();
    const user = useUser();

    const isAllowed = true
    
    // useMemo(
    //   () =>
    //    token && 
    //     (!authType ||
    //       get(user, ['employments', '0', 'user_type']) === authType),
    //   [(refreshToken, token, user, options)]
    // );

    useEffect(() => {
      if ((!token) || (token && !user) || isAllowed) return;

      NotificationManager.info(
        t('validation:errors.auth.permission_denied_details'),
        t('validation:errors.auth.permission_denied')
      );
    }, [user, token]);

    return (
      <>
        {token && !user ? (
          <LoadingDots
            style={{
              position: 'absolute',
              top: 115,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto',
            }}
          />
        ) : !isAllowed ? (
          <Redirect to={redirectTo || AUTH.login} />
        ) : (
          <WrappedComponent />
        )}
      </>
    );
  };

  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  AuthenticatedComponent.displayName = `Authenticated(${wrappedComponentName})`;

  return AuthenticatedComponent;
};

type AuthType = 'ADMIN' | 'DELEGATE';
type RequireAuth = (
  WrappedComponent: ComponentType<any>,
  options?: { authType?: AuthType, redirectTo?: string }
) => ComponentType<any>;

export default requiresAuth;
