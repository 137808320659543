// @flow
import * as React from 'react';

/**
 * Send svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
            <path d="M.01 17L20 8.5.01 0 0 6.611 14.286 8.5 0 10.389z" />
        </svg>
    );
};
