// @flow
import * as React from 'react';

/**
 * Logout svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18">
            <g fill="none" fillRule="evenodd">
                <path fill="#FFF" fillRule="nonzero" stroke="#1083C6" d="M.5.5h14v17H.5z"/>
                <path fill="#FFF" fillRule="nonzero" d="M14 5h1v8h-1z"/>
                <text fill="#1083C6" fontFamily="LucidaGrande, Lucida Grande" fontSize="15">
                    <tspan x="7" y="17">→</tspan>
                </text>
            </g>
        </svg>
    );
};
