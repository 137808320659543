import { Reducer } from 'redux';
import {
  ActionTypes,
  GET_ORGANIZATIONS_REQUEST, GET_ORGANIZATIONS_SUCCESS, GET_ORGANIZATIONS_FAILURE,
} from '../actions/organization';


export interface Organization {
  uuid: string;
  organization: string;
}

interface State {
  organizations: Organization[];
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  organizations: [],
  loading: false,
  error: null,
};

const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATIONS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_ORGANIZATIONS_SUCCESS:
      return { ...state, organizations: action.payload, loading: false, error: null };
    case GET_ORGANIZATIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default reducer;
