// @flow
import * as React from 'react';

/**
 * Terms svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18">
            <g fill="none" fillRule="nonzero">
                <path fill="#FFF" stroke="#1083C6" d="M.5 17.5V.5h10.289L14.5 4.196V17.5H.5z"/>
                <path fill="#1083C6" d="M10 5h1V1h-1z"/>
                <path fill="#1083C6" d="M10 5h5V4h-5zM3 4h5v1H3zM3 7h9v1H3zM3 13h9v1H3zM3 10h5v1H3z"/>
            </g>
        </svg>
    );
};
