export const AUTH = {
  login: '/login',
  register: '/register',
  activate: '/activate',
  reset_password: '/reset-password',
  // update_password: '/update-password',
};

export const PUBLIC = {
  terms_of_use: '/terms',
  privacy_policy: '/privacy',
  website: '/website',
};

/* Authenticated access only */
export const PRIVATE = (() => {
  const root = '/app';

  return {
    dashboard: root,
    profile: `${root}/profile`,
    search_results: `${root}/search-results`,
    talents: `${root}/talents`,
    validations: `${root}/validations`,
    report: `${root}/iv-report`,
    lev_report: `${root}/lev-report`,
    old_report: `${root}/report`,
  };
})();

/* Admin access only */
export const ADMIN = (() => {
  const root = '/admin';

  return {
    delegates: `${root}/users`,
    plans: `${root}/plans`,
    integrations: `${root}/integrations`,
    billing: `${root}/billing`,
    invoices: `${root}/invoices`,
    company: `${root}/company`,
    teams: `${root}/teams`,
    team:`${root}/team/:tid`
  };
})();
