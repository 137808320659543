import { Reducer } from 'redux';
import {
    ActionTypes,
    GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_FAILURE,
    ADD_USER_REQUEST, ADD_USER_SUCCESS, ADD_USER_FAILURE,
    EDIT_USER_REQUEST, EDIT_USER_SUCCESS, EDIT_USER_FAILURE,
    TRANSFER_OWNERSHIP_REQUEST, TRANSFER_OWNERSHIP_SUCCESS, TRANSFER_OWNERSHIP_FAILURE
} from '../actions/users';

export interface User {
    uuid: string;
    firstName: string;
    lastName: string;
    email: string;
    isActive: boolean,
    isOwner: boolean,
    accessType: "ADMIN" | "OWNER" | "LEAD" | "MEMBER" ,
    team:any
}

interface State {
    users: User[];
    loading: boolean;
    error: string | null;
    addUserLoading: boolean;
    editUserLoading: boolean;
    transferOwnershipLoading: boolean;
}

const initialState: State = {
    users: [],
    loading: false,
    error: null,
    addUserLoading: false,
    editUserLoading: false,
    transferOwnershipLoading: false
};

const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS_REQUEST:
            return { ...state, loading: true, error: null };
        case GET_USERS_SUCCESS:
            return { ...state, users: action.payload, loading: false, error: null };
        case GET_USERS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ADD_USER_REQUEST:
            return { ...state, addUserLoading: true };
        case ADD_USER_SUCCESS:
            return { ...state, addUserLoading: false };
        case ADD_USER_FAILURE:
            return { ...state, addUserLoading: false };
        case EDIT_USER_REQUEST:
            return { ...state, editUserLoading: true };
        case EDIT_USER_SUCCESS:
            return { ...state, editUserLoading: false };
        case EDIT_USER_FAILURE:
            return { ...state, editUserLoading: false };
        case TRANSFER_OWNERSHIP_REQUEST:
            return { ...state, transferOwnershipLoading: true };
        case TRANSFER_OWNERSHIP_SUCCESS:
            return { ...state, transferOwnershipLoading: false };
        case TRANSFER_OWNERSHIP_FAILURE:
            return { ...state, transferOwnershipLoading: false };
        default:
            return state;
    }
};

export default reducer;
