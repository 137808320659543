import { makeStyles } from '@mui/styles';

const styles = makeStyles((theme: any) => ({
  dialog: {
    padding: '12px 48px',
    [theme.breakpoints.down('md')]: {
      padding: '4px 8px',
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
  image: {
    margin: '0 auto 24px',
    maxWidth: 400,
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

export default styles;
