// @flow
import * as React from 'react';

/**
 * Dashboard svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <circle cx="12" cy="12" r="11.5" stroke="#1083C6"/>
                <circle cx="12" cy="12" r="8.5" stroke="#1083C6"/>
                <path fill="#FFF" fillRule="nonzero" d="M12 22.414c5.523 0 10-4.477 10-10s-20-5.523-20 0 4.477 10 10 10z"/>
                <path fill="#1083C6" fillRule="nonzero" stroke="#1083C6" d="M1.5 15.5h21v1h-21z"/>
                <path fill="#FFF" fillRule="nonzero" d="M3 10h18v2H3z"/>
                <path fill="#1083C6" fillRule="nonzero" stroke="#1083C6" d="M9.758 8.5H10l-.337-.222-.203-.475L9 8.5h.67l2.572 6H12l.337.222.203.475.46-.697h-.67l-2.572-6z"/>
            </g>
        </svg>
    
    );
};
