// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { RouterHistory } from 'react-router-dom';

import { setRememberMeToken } from 'utils/local-storage';
import { toggleLanguage } from 'utils/i18n';
import { PRIVATE, ADMIN } from 'router/routes';
import { useSetToken, useUser } from 'utils/contexts/app-context';
import { clearAll } from 'state/ducks/global';
import ImagePlaceHolder from 'components/image-placeholder';
import useOutsideClick from 'hooks/useOutsideClick';
import { Flags } from 'providers/FlagsProvider';
import FeatureFlags from 'utils/feature-flags';
import styles from './styles.module.scss';

type Props = {
  history: RouterHistory,
  picture: ?string,
  clearState: () => void,
};

const ProfilePictureWithDropdown = ({
  history,
  picture,
  clearState,
}: Props) => {
  const setToken = useSetToken();
  const user = useUser();
  const [containerRef, didClickOutside] = useOutsideClick();
  const accountRef = React.useRef();
  const [isDropdownVisible, setDropdownVisible] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (isDropdownVisible && didClickOutside) {
      setDropdownVisible(false);
    }
  }, [isDropdownVisible, didClickOutside, setDropdownVisible]);

  /** Actions */

  const onEditProfile = () => {
    history.push(PRIVATE.profile);
    setDropdownVisible(false);
  };

  const onUsersClick = () => {
    if(user.accessLevel === 'LEAD' ){
      history.push(`/admin/team/${user.team_id}`)
    }else{
      history.push(ADMIN.delegates);
    }
    setDropdownVisible(false);
  };

  const onBilling = () => {
    if (user && user.isAdmin) {
      history.push(ADMIN.plans);
      setDropdownVisible(false);
    }
  };

  const onIntegrations = () => {
    if (user && user.isAdmin) {
      history.push(ADMIN.integrations);
      setDropdownVisible(false);
    }
  };

  const onLogout = () => {
    setRememberMeToken(null);
    setToken(null);
    clearState();
    setDropdownVisible(false);
  };
  


  React.useEffect(() => {
    // Function to calculate and set the top position of the dropdown container
    const setPosition = () => {
      const imagePlaceholder = document.getElementById('account_settings_circle');
      const dropdownContainer = document.getElementById('profile_dropdown_container');

      if (imagePlaceholder && dropdownContainer) {
        // Calculate the bottom position of the ImagePlaceHolder div
        const bottomPosition = imagePlaceholder.offsetTop + imagePlaceholder.offsetHeight + 15;

        // Set the top style value of the dropdown container
        dropdownContainer.style.top = `${bottomPosition}px`;
      }
    };

    // Call setPosition when isDropdownVisible changes
    setPosition();

    // Add an event listener for window resize (optional, to reposition on resize)
    window.addEventListener('resize', setPosition);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', setPosition);
    };
  }, [isDropdownVisible]);

  return (
    <div ref={containerRef} className={styles.profile_picture_holder}>
      <div
        
        className={styles.image_wrapper}
        onClick={() => setDropdownVisible(!isDropdownVisible)}
        id="account_settings_circle"
        
      >
        <ImagePlaceHolder src={picture}  />
      </div>

      {isDropdownVisible && (
        <div className={styles.profile_dropdown_container} id="profile_dropdown_container" style={{top:0}}>
          {/* Edit Profile */}
          <div className={styles.profile_dropdown_row} onClick={onEditProfile}>
            <div className={styles.arrow_up}>
              <div />
            </div>
            <span className={styles.profile_dropdown_text}>
              {t('nouns.settings')}
            </span>
          </div>

          {user && (user.isAdmin || user.accessLevel === 'LEAD') && (
            <div className={styles.profile_dropdown_row} onClick={onUsersClick}>
              <span className={styles.profile_dropdown_text}>
                {t('nouns.user_management')}
              </span>
            </div>
          )}

          {/* Billing Information */}
          <Flags authorizedFlags={[FeatureFlags.AllowBilling]}>
            {user && user.isAdmin && (
              <div className={styles.profile_dropdown_row} onClick={onBilling}>
                <span className={styles.profile_dropdown_text}>
                  {t('nouns.plans_and_payments')}
                </span>
              </div>
            )}
          </Flags>

          {/* Integrations */}
          <Flags authorizedFlags={[FeatureFlags.AllowIntegrations]}>
            <>
              {user && user.isAdmin && (
                <div
                  className={styles.profile_dropdown_row}
                  onClick={onIntegrations}
                >
                  <span className={styles.profile_dropdown_text}>
                    {t('nouns.integrations')}
                  </span>
                </div>
              )}
            </>
          </Flags>

          {/* Switch language */}
          <div className={styles.profile_dropdown_row} onClick={toggleLanguage}>
            <span className={styles.profile_dropdown_text}>
              {t('nouns.switch_language')}
            </span>
          </div>

          {/* Log out */}
          <div className={styles.profile_dropdown_row} onClick={onLogout}>
            <span className={styles.profile_dropdown_text}>
              {t('verbs.logout')}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  clearState() {
    dispatch(clearAll());
  },
});

const ConnectedComponent = connect(
  null,
  mapDispatchToProps
)(ProfilePictureWithDropdown);

export default withRouter(ConnectedComponent);
