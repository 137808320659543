enum FeatureFlags {
  AllowRegister = 'auth/allow-register',
  AllowDashboard = 'private/allow-dashboard',
  AllowSearchAll = 'private/search-all',
  AllowTalents = 'private/allow-talents',
  AllowValidations = 'private/allow-validations',
  AllowReferences = 'private/allow-references',
  AllowTwitter = 'private/allow-twitter',
  AllowDelegates = 'private/allow-delegates',
  AllowBilling = 'admin/allow-billing',
  AllowIntegrations = 'admin/allow-integrations',
}

export default FeatureFlags;
