// @flow
import * as React from 'react';

/**
 * Privacy svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18">
            <g fill="none" fillRule="nonzero">
                <rect width="8" height="17" x="3.5" y=".5" fill="#FFF" stroke="#1083C6" rx="4"/>
                <path fill="#FFF" stroke="#1083C6" d="M.5 7.5h14v10H.5z"/>
                <path fill="#1083C6" d="M1 10h4v1H1zM1 12h4v1H1zM1 14h4v1H1z"/>
            </g>
        </svg>
    );
};
