// @flow
import * as React from 'react';

/**
 * Little check-mark svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
            <g fill="none" fillRule="nonzero">
                <circle cx="7.5" cy="7.5" r="7.5" fill="#888"/>
                <path fill="#FFF" d="M6.5 8.542l4.06-4.06 1.415 1.413-4.06 4.061.06.06-1.414 1.415-.061-.06-.06.06-1.415-1.414.06-.06-2.06-2.062L4.44 6.481 6.5 8.541z"/>
            </g>
        </svg>

    );

}