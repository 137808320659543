import { Dispatch, Action } from 'redux';
import axios from 'axios';
import { Organization } from '../reducers/organization'
import { NotificationManager } from 'react-notifications';


// Action types
export const GET_ORGANIZATIONS_REQUEST = 'GET_ORGANIZATIONS_REQUEST';
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS';
export const GET_ORGANIZATIONS_FAILURE = 'GET_ORGANIZATIONS_FAILURE';

interface GetOrganizationsRequest extends Action<typeof GET_ORGANIZATIONS_REQUEST> { }
interface GetOrganizationsSuccess extends Action<typeof GET_ORGANIZATIONS_SUCCESS> {
  payload: Organization[]
}
interface GetOrganizationsFailure extends Action<typeof GET_ORGANIZATIONS_FAILURE> {
  payload: string
}


export type ActionTypes = GetOrganizationsRequest | GetOrganizationsSuccess | GetOrganizationsFailure

// Action creators
const getOrganizationsRequest = (): GetOrganizationsRequest => ({ type: GET_ORGANIZATIONS_REQUEST });
const getOrganizationsSuccess = (data: Organization[]): GetOrganizationsSuccess => ({ type: GET_ORGANIZATIONS_SUCCESS, payload: data });
const getOrganizationsFailure = (error: string): GetOrganizationsFailure => ({ type: GET_ORGANIZATIONS_FAILURE, payload: error });


// Async action creators
const getOrganizations = () => {
  const token = window.localStorage.getItem('token')
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getOrganizationsRequest());
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_SERVER_URL}/app/organizations/all`
        , {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      dispatch(getOrganizationsSuccess(response.data.organizations || []))

    } catch (error: any) {
      console.log('error', error)
      NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
      dispatch(getOrganizationsFailure(error.message));
    }
  };
};

const actions = {
  getOrganizations
}

export default actions
