import { Reducer } from 'redux';
import {
  ActionTypes,
  GET_TEAMS_REQUEST, GET_TEAMS_SUCCESS, GET_TEAMS_FAILURE,
  GET_TEAM_REQUEST, GET_TEAM_SUCCESS, GET_TEAM_FAILURE,
  ADD_TEAM_REQUEST, ADD_TEAM_SUCCESS, ADD_TEAM_FAILURE,
  EDIT_TEAM_REQUEST, EDIT_TEAM_SUCCESS, EDIT_TEAM_FAILURE,
  DELETE_TEAM_REQUEST, DELETE_TEAM_SUCCESS, DELETE_TEAM_FAILURE
} from '../actions/teams';

export interface TeamUser {
  id: number;
  employment_id:number;
  username: string;
  first_name: string,
  last_name: string,
  email: string;
  status: "ACTIVE" | "INACTIVE",
  user_type: "ADMIN" | "OWNER" | "LEAD" | "MEMBER"
}

export interface Team {
  uuid: string;
  name: string;
  allowsPrivacy: boolean;
  created_at: string;
  is_default: boolean;
  alocatedValidations: number;
  user_count: string;
  users?: TeamUser[];
  validationConsumptionCount: string | number;
}

interface State {
  teams: Team[];
  team: Team | {};
  loading: boolean;
  error: string | null;
  addTeamLoading: boolean;
  editTeamLoading: boolean;
  deleteTeamLoading: boolean;
}

const initialState: State = {
  teams: [],
  team: {},
  loading: false,
  addTeamLoading: false,
  editTeamLoading: false,
  deleteTeamLoading: false,
  error: null,
};

const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEAMS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_TEAMS_SUCCESS:
      return { ...state, teams: action.payload, loading: false, error: null };
    case GET_TEAMS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case GET_TEAM_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_TEAM_SUCCESS:
      return { ...state, team: action.payload, loading: false, error: null };
    case GET_TEAM_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ADD_TEAM_REQUEST:
      return { ...state, addTeamLoading: true };
    case ADD_TEAM_SUCCESS:
      return { ...state, addTeamLoading: false };
    case ADD_TEAM_FAILURE:
      return { ...state, addTeamLoading: false };
    case EDIT_TEAM_REQUEST:
      return { ...state, editTeamLoading: true };
    case EDIT_TEAM_SUCCESS:
      return { ...state, editTeamLoading: false };
    case EDIT_TEAM_FAILURE:
      return { ...state, editTeamLoading: false };
    case DELETE_TEAM_REQUEST:
      return { ...state, deleteTeamLoading: true };
    case DELETE_TEAM_SUCCESS:
      return { ...state, deleteTeamLoading: false };
    case DELETE_TEAM_FAILURE:
      return { ...state, deleteTeamLoading: false };
    default:
      return state;
  }
};

export default reducer;
