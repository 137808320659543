// @flow

import React, { useEffect, useState } from 'react';
import type { ComponentType } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import { ADMIN } from 'router/routes';
import { NotificationManager } from 'react-notifications';
import { useFlags } from 'providers/FlagsProvider';
import {
  usePlanSubscription,
} from 'utils/contexts/billing-context';
import { useUser, useSetToken } from 'utils/contexts/app-context';
import PopUp from 'components/PopUp';
import ReversibleButton from 'components/reversible-button';
import FeatureFlags from '../feature-flags';

/* eslint-disable */

const requiresPlan: RequireBilling = (WrappedComponent, redirect = true) => {
  const ActiveComponent = ({ history }) => {
    const [banner, setBanner] = useState<BannerState>(null);

    const { t } = useTranslation();
    const user = useUser();
    const userType = get(user, ['employments', '0', 'user_type']);
    const setToken = useSetToken();
    const { planSub: planSubscription } = usePlanSubscription();


    const noPlanPopup = () => (
        <PopUp
          isVisible
          title={t('sentences:pages.plans.company_not_registered')}
          description={t(
            'sentences:pages.plans.company_not_registered_details'
          )}
          actionButtons={[
            <ReversibleButton onClick={() => setToken(null)}>
              {t('verbs.logout')}
            </ReversibleButton>,
          ]}
        />
      );

    const noSubscriptionActions = {
      ADMIN: () =>  noPlanPopup(),
      DELEGATE: noPlanPopup,
      default: () => null,
    };

    if (redirect && user && planSubscription !== null && isEmpty(planSubscription) ) {
      return (
        noSubscriptionActions['ADMIN'] || noSubscriptionActions['default']
      )();
    }

    return <WrappedComponent banner={banner} />;
  };

  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  ActiveComponent.displayName = `Authenticated(${wrappedComponentName})`;

  return withRouter(ActiveComponent);
};

type BannerState = {
  message: string | null,
  button: {
    text: string,
    action: () => void,
  } | null,
  state: 'info' | 'error' | 'warning',
} | null;

type RequireBilling = (
  WrappedComponent: ComponentType<any>
) => ComponentType<any>;

export default requiresPlan;
