// @flow
import * as React from "react";
import styles from "./styles.module.scss";

type Props = {
    style?: {},
    dark?: boolean,
}

const LoadingDots = ({ style, dark }: Props) => (
    <div data-testid="loader" className={dark ? `${styles["lds-ellipsis"]} ${styles["dark"]}` : styles["lds-ellipsis"]} style={style}>
        <div /><div /><div /><div />
    </div>
);

export default LoadingDots;
