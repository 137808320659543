// @flow
import * as React from 'react';

/**
 * Little suitcase
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14">
            <g fill="none" fillRule="nonzero">
                <path fill="#888" d="M0 3h15v11H0z"/>
                <rect width="9" height="4" x="3" fill="#888" rx="2"/>
                <path fill="#FFF" d="M5 2h5v1H5z"/>
            </g>
        </svg>
    );

}