// @flow
import * as React from 'react';

/**
 * Little email
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11">
            <g fill="none" fillRule="nonzero">
                <path fill="#888" d="M0 0h15v11H0z"/>
                <path fill="#FFF" d="M6 6h1v1H6V6zM5 5h1v1H5V5zM4 4h1v1H4V4zM3 3h1v1H3V3zM2 2h1v1H2V2zM1 1h1v1H1V1zM9 6H8v1h1V6zm1-1H9v1h1V5zm1-1h-1v1h1V4zm1-1h-1v1h1V3zm1-1h-1v1h1V2zm1-1h-1v1h1V1zM7 7h1v1H7z"/>
            </g>
        </svg>

    );

}