// @flow
import * as React from 'react';
import { NoProfilePicture } from '../svg-icons';

import styles from './styles.module.scss';

type Props = {
    src: ?string,
    alt: string
};

type State = {
    isUrlValid: boolean
}


/**
 * The image place holder component
 *
 * @param props
 * @return {XML}
 */
export default class extends React.PureComponent<Props, State> {

    img: HTMLImageElement;

    state = {
        isUrlValid: false
    };

    static defaultProps = {
        alt: ''
    };


    componentDidMount() {
        this.handleImageLoading();
    }

    componentWillUnmount() {
        this.img.removeEventListener('load', this.setUrlValid);
    }
    

    componentDidUpdate(prevProps: Props) {

        if(prevProps.src !== this.props.src) {
            this.handleImageLoading();
        }

    }

    setUrlValid = () => this.setState({ isUrlValid: true });

    handleImageLoading = (): void => {

        this.img = new Image();

        this.img.addEventListener('load', this.setUrlValid, false);

        // Ok, we are using a maybe type, because we want this component to always have a src property. However, the
        // src property could be null or undefined. So we have to refined the type. The best place do to so would be
        // at the beginning of the method. However, the type refinement will be invalidate by the addEventListener
        // method. So we are doing it here, event if it doesn't make a lot of sense.
        if(typeof this.props.src === 'string') {

            this.img.alt = this.props.alt;
            this.img.src = this.props.src;

        }

    };

    render() {

        const { isUrlValid } = this.state;
        const { src, alt } = this.props;

        // The image is most likely already cached by the browser, so we can re-use the img src a second time without
        // doing useless network calls
        return (
            <React.Fragment>
                { isUrlValid ?
                    <img src={src} alt={alt} className={styles.loaded_picture} /> :
                    <NoProfilePicture className={styles.fallback_svg} />
                }
            </React.Fragment>
        );

    }

}
