// @flow
import * as React from 'react';

/**
 * Little flag
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
            <path fill="#888" fillRule="nonzero" d="M12 6l2-5H2c0-.6-.4-1-1-1S0 .4 0 1v14c0 .6.4 1 1 1s1-.4 1-1v-4h12l-2-5z"/>
        </svg>
    );

}