// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';
import { Search } from '@mui/icons-material';

import { PRIVATE } from 'router/routes';
import { openDrawer, closeDrawer } from 'state/ducks/drawer';
import {
  setSearchSameCompany,
  setSearchOtherCompanies,
} from 'state/ducks/talents';

import useAPI from 'hooks/useAPI';
import useOutsideClick from 'hooks/useOutsideClick';
import { useCompany } from 'utils/contexts/app-context';
import { useFlags } from 'providers/FlagsProvider';
import FeatureFlags from 'utils/feature-flags';
import styles from './styles.module.scss';
import Suggestions from './suggestions';

type Props = {
  count: number,
  results: Talent[],
  setSameCompanyResults: (talents: Talent[]) => void,
  setOtherCompaniesResults: (talents: Talent[]) => void,
  openDrawer: (talent: Talent, sameCompany: boolean) => void,
  closeDrawer: () => void,
  onFocus: () => void,
  onBlur: () => void,
  isMobileBarOpen?: boolean,
  isDrawerOpen: boolean,
  history: RouterHistory,
};

const getSearchResults = async (
  API,
  options
) => {
  const response = await API.get('validations/search', {
    params: {
      query: options.value,
    },
  });
  return response.data.validations;
};

/** ************************************************
 * SearchBar component
 ************************************************* */

const SearchBar = (props: Props) => {
  /** Network helper */
  const API = useAPI();
  const [ref, didClickOutside] = useOutsideClick();
  const inputRef = React.useRef();
  const { t } = useTranslation();

  const company = useCompany();

  /** State */
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [hasFocus, setFocus] = React.useState<boolean>(false);
  const [index, setIndex] = React.useState<number>(0);
  const [sameCompanyResults, setSameCompanyResults] = React.useState<Talent[]>(
    []
  );
  const [otherCompaniesResults, setOtherCompaniesResults] = React.useState<
    Talent[]
  >([]);
  const results = [...sameCompanyResults, ...otherCompaniesResults].slice(0, 5);
  const sameCompanyEmploymentIds = sameCompanyResults.map(
    employment => employment.validation.uuid
  );

  const { matchedFlags } = useFlags({
    authorizedFlags: [
      FeatureFlags.AllowSearchAll,
      FeatureFlags.AllowTalents,
      FeatureFlags.AllowValidations,
    ],
  });

  const canSearchAll = FeatureFlags.AllowSearchAll in matchedFlags;

  /** Data fetch */

  const fetchData = React.useCallback(
    async val => {
      if (!company) return;
      if (!loading) {
        setLoading(true);

        setSameCompanyResults([])
        const first = await getSearchResults(API, {
          value: val,
        });
       
        if (val.length > 0) {
          setSameCompanyResults(first);
          // setOtherCompaniesResults(second);
        }
        

        setLoading(false);
      }
    },
    [
      loading,
      setLoading,
      setSameCompanyResults,
      setOtherCompaniesResults,
      API,
      company,
    ]
  );

  React.useEffect(() => {
    if (didClickOutside) {
      setFocus(false);
    }
  }, [didClickOutside, fetchData]);

  const handleChange = React.useCallback(
    e => {
      if (value !== e.target.value) {
        setValue(e.target.value);
        if (e.target.value.length > 2) {
          fetchData(e.target.value);
        } else {
          setSameCompanyResults([]);
          setOtherCompaniesResults([]);
        }
      }
    },
    [
      value,
      setValue,
      fetchData,
      setSameCompanyResults,
      setOtherCompaniesResults,
    ]
  );

  /** Reset index every time something happens with value or focus */
  React.useEffect(() => {
    setIndex(0);
  }, [value, hasFocus]);

  /** Reset value every time something happens with focus */
  React.useEffect(() => {
    setValue('');
    setSameCompanyResults([]);
    setOtherCompaniesResults([]);
  }, [hasFocus, setValue, setSameCompanyResults, setOtherCompaniesResults]);

  /** Blur input when losing focus */
  React.useEffect(() => {
    if (!hasFocus && inputRef.current != null) {
      inputRef.current.blur();
    }
  }, [hasFocus, inputRef]);

  /** Actions */

  const onKeyDown = e => {
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 38 && index > 0) {
      e.preventDefault();
      setIndex(index - 1);
    } else if (e.keyCode === 40 && index < results.length) {
      e.preventDefault();
      setIndex(index + 1);
    }
  };

  const handleFormSubmit = e => {
    e.preventDefault();

    if (matchedFlags.includes(FeatureFlags.AllowTalents)) {
      if (index === results.length) {
        // After all items, there is the `Show all` row that can be selected.
        searchAll();
      } 
      // else {
      //   props.openDrawer(
      //     results[index],
      //    false
      //   );
      //   setFocus(false);
      // }
    } else {
      if (results[index] && results[index].id){
        props.history.push(`${PRIVATE.report}/${results[index].id}`);
       
      }
      setFocus(false);
    }
  };

  const searchAll = () => {
    props.setSameCompanyResults(sameCompanyResults);
    props.setOtherCompaniesResults(otherCompaniesResults);
    const encodedValue = value.replace(/ /g, '+');
    props.history.push({
      pathname: PRIVATE.search_results,
      search: `search=${encodedValue}`,
    });
    if (props.isDrawerOpen) {
      props.closeDrawer();
    }
    setFocus(false);
  };

  const handleSuggestionClick = (talent: Talent) => {
      props.history.push(`${talent.validation.type=== "LEGAL_ENTITY" ?PRIVATE.lev_report : PRIVATE.report}/${talent.validation.uuid}`);
    setFocus(false);
  };

  /** Component */
  return (
    <form
      ref={ref}
      className={`${styles.search_bar} ${
        props.isMobileBarOpen ? styles.clicked : ''
      }`}
      onSubmit={handleFormSubmit}
    >
      <div className={styles.search_bar_contents}>
        <div
          className={styles.search_icon}
          onClick={() =>
            inputRef.current != null ? inputRef.current.focus() : {}
          }
        >
          <Search />
        </div>
        <input
          ref={inputRef}
          className={`${styles.search_input} ${
            props.isMobileBarOpen ? styles.clicked : ''
          }`}
          placeholder={t('sentences:search_placeholder')}
          value={value}
          onChange={handleChange}
          onFocus={() => {
            setFocus(true);
            if (props.onFocus) props.onFocus();
          }}
          onBlur={() => {
            if (props.onBlur) {
              setFocus(false);
              props.onBlur();
            }
          }}
          onKeyDown={onKeyDown}
        />
      </div>
      {hasFocus && (
        <Suggestions
          results={results}
          index={index}
          onSubmit={handleSuggestionClick}
          value={value}
          onShowAll={searchAll}
          showAll={matchedFlags.includes(FeatureFlags.AllowTalents)}
        />
      )}
    </form>
  );
};

const mapStateToProps = state => ({
  isDrawerOpen: state.drawer.isOpen,
});

const mapDispatchToProps = dispatch => ({
  openDrawer(talent: Talent, sameCompany: boolean = false) {
    dispatch(openDrawer({ talent, sameCompany }));
  },
  closeDrawer() {
    dispatch(closeDrawer());
  },
  setSameCompanyResults(list: Talent[]) {
    dispatch(setSearchSameCompany({ list }));
  },
  setOtherCompaniesResults(list: Talent[]) {
    dispatch(setSearchOtherCompanies({ list }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SearchBar));
