// @flow
import * as React from 'react';

/**
 * Delegates svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
            <defs>
                <circle id="b" cx="12" cy="12" r="12"/>
                <circle id="c" cx="12" cy="12" r="12"/>
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask id="d" fill="#fff">
                    <use xlinkHref="#b"/>
                </mask>
                <g fillRule="nonzero" stroke="#1083C6" strokeWidth="1.059" mask="url(#d)">
                    <path d="M12 14.596c2.224 0 4.042-1.79 4.042-3.971V9.5c0-2.18-1.818-3.97-4.042-3.97-2.224 0-4.042 1.79-4.042 3.97v1.125c0 2.18 1.818 3.97 4.042 3.97zM4.532 22.818l7.452 9.335 7.483-9.335c-.094-2.768-2.334-4.914-5.181-4.914H9.714c-2.847 0-5.087 2.146-5.182 4.914z"/>
                </g>
                <circle cx="12" cy="12" r="11.5" stroke="#1083C6"/>
            </g>
        </svg>
    );
};
