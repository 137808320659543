// @flow
import * as React from 'react';
import { Motion, spring } from 'react-motion';

type Props = {
    open: boolean | null,
    children: (
        translateStyle: {},
        opacityStyle: {},
        shouldDisplayInput: boolean,
        rawRatio: number
    ) => React.Node
}


const ANIM_PARAM: SpringHelperConfig = { stiffness: 100, damping: 20 };

/**
 * Generate the translation style
 *
 * @param value
 * @return {{transform: string}}
 */
const generateTranslationStyle = (value: number): {} => {

    // Ok you have to know that the navbar is split in 5
    const xTranslation = window.innerWidth / 5 * 2;


    return { transform: `translateX(${ -value * xTranslation }px)` };

};

/**
 * Generate the opacity style
 *
 * @param value
 */
const generateOpacityStyle = (value: number): {} => {


    const interpolatedOpacity = 1 - value;

    return { opacity: interpolatedOpacity };

};

/**
 * Calculate the display of the input
 *
 * @param value
 * @param to
 * @param open
 */
const shouldDisplayInput = (value: number, to: number, open: boolean | null): boolean => {

    return (value > to * 0.95) && !!open;

};

/**
 * This is a very special component. The main goal is to encapsulate all the logic of the nav bar animation. I don't
 * know if this is a good idea ? Time will tell !
 *
 * @param props
 * @return {XML}
 */
export default (props: Props) => {

    let from, to;

    if(props.open === null) {
        from = 0;
        to = 0;
    } else if(props.open === true) {
        from = 0;
        to = 1;
    } else {
        from = 1;
        to = 0;
    }


    return (
        <Motion
            defaultStyle={{x: from}}
            style={{x: spring(to, ANIM_PARAM)}}
        >
            { values =>
                <React.Fragment>
                    {props.children(
                        generateTranslationStyle(values.x),
                        generateOpacityStyle(values.x),
                        shouldDisplayInput(values.x, to, props.open),
                        values.x
                    )}
                </React.Fragment>
            }
        </Motion>
    );

}