// @flow
import * as React from 'react';

/**
 * EditProfile svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18">
            <g fill="none" fillRule="nonzero" stroke="#1083C6">
                <path d="M7.941 9.625c2.22 0 4.038-1.802 4.038-4V4.5c0-2.198-1.817-4-4.038-4-2.22 0-4.038 1.802-4.038 4v1.125c0 2.198 1.818 4 4.038 4zM.523 17.5H15.36c-.246-2.63-2.418-4.625-5.149-4.625H5.672C2.942 12.875.77 14.87.523 17.5z"/>
            </g>
        </svg>
    );
};
