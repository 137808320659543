import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Typography, Dialog, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { PRIVATE } from 'router/routes';
import useStyles from './styles';
import SecureLogin from '../../assets/images/secure_login.svg';

interface Props {
  open: boolean;
}

const EnableTwoFA: React.FC<Props> = ({ open }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  // const updateUser = useUpdateUser();

  // const markAsSeen = async () => {
  //   await API.patch('/auth/me', { seen: { enableTwoFA: true } });
  //   await updateUser();
  // };

  const enableTwoFA = () => {
    // markAsSeen();
    history.push({
      pathname: PRIVATE.profile,
      state: { section: 'SECURITY', enableTwoFA: true },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      maxWidth="sm"
      classes={{ paper: classes.dialog }}
    >
      <img
        alt="two-step-verification"
        src={SecureLogin}
        className={classes.image}
      />
      <Typography variant="h1" align="center" className={classes.title}>
        <Trans i18nKey="sentences:announcement.2fa.title">
          Keep your account secure with
          <br /> Two-step Verification
        </Trans>
      </Typography>
      <br />
      <Typography variant="subtitle1" color="textSecondary" align="center">
        {t('sentences:announcement.2fa.description')}
      </Typography>
      <br />
      <Typography variant="subtitle1" color="textSecondary" align="center">
        <Trans i18nKey="sentences:announcement.2fa.note">
          Your organisation requires to activate two-step verification
        </Trans>
      </Typography>

      <Box mt={4} display="flex" flexDirection="column">
        <Button variant="contained" color="primary" onClick={enableTwoFA}>
          {t('sentences:announcement.2fa.action_main')}
        </Button>
        {/* <Button variant="text" color="inherit" onClick={markAsSeen}>
          {t('sentences:announcement.2fa.action_secondary')}
        </Button> */}
      </Box>
    </Dialog>
  );
};

export default EnableTwoFA;
