export default {
  adverbs: {
    by: 'par',
    for_more_than_days: 'depuis plus de {count}} jours',
    since: 'depuis',
    on: 'le',
    as_of: 'en date du',
    as_in: 'tel que dans',
    out_of: 'sur',
    per_month: 'par mois',
  },
  nouns: {
    '2fa': 'Vérification à deux étapes',
    account: 'compte',
    active: 'actif',
    active_users: 'utilisateurs actifs',
    active_users_capitalized: 'Utilisateurs actifs',
    actives: 'actifs',
    addiction: 'dépendance',
    advanced: 'avancées',
    address: {
      line_1: "Ligne d'adresse 1",
      line_2: "Ligne d'adresse 2",
      city: 'Ville',
      country: 'Pays',
      postal_code_zip: 'Code postal/ZIP',
      province_state: 'Province/État',
    },
    admin: 'administrateur',
    all: 'Tout',
    annual_instant_validation: 'rapports de candidats par année',
    api_key: 'clé API',
    available: 'disponible',
    available_pending_twitter:'Disponible, en attente de vérification de Twitter',
    available_pending_articles:'Disponible, en attente de vérification des articles',
    basic_information: 'Informations de base',
    preference_settings: 'Paramètres de préférence',
    billing: {
      billing: 'Facturation',
      email: 'Courriel de facturation',
      billing_information: 'Détails de facturation',
      billing_address: 'Adresse de facturation',
      next_payment: 'Prochain paiement',
      payment_details: 'Détails de paiement',
      payment_method: 'Mode de paiement',
      save_changes: 'Sauvegarder les modifications',
    },
    plans_and_payments: 'plans et facturation',
    city_of_residence: 'ville de résidence',
    clear: 'rien à signaler',
    clear_all: 'supprimer les filtres',
    coming_soon: 'Bientôt disponible',
    company: 'entreprise',
    affiliated_organization: 'Organisations affiliées',
    company_name: "Nom de l'entreprise",
    company_plural: 'entreprises',
    conduct: 'Indicateur de risque',
    report_type: 'Type de rapport',
    connected_as: 'connecté comme',
    confirm_password: 'confirmer votre mot de passe',
    consent_status: 'invitation',
    consent_statuses: 'invitations',
    copyright: '© {{year}} Valital Technologies Inc.',
    sso: 'connectez-vous avec SSO',
    // create_password: 'créez votre mot de passe ',
    credentials: 'Emplois',
    free: 'Gratuit',
    from: 'De',
    custom: 'personnalisé',
    custom_plan: '<span><strong>Plan</strong> personnalisé</span>',
    dashboard: 'tableau de bord',
    delegate: 'utilisateur',
    delegate_1: 'Un utilisateur',
    delegate_plural: 'utilisateurs',
    department: 'département',
    discrimination: 'discrimination',
    done: 'terminé',
    downgrade: 'sélectionner',
    email: 'courriel',
    end_date: 'Date de fin',
    enrolment_period: "Période de l'inscription",
    features: 'fonctionnalités',
    fraud: 'fraude',
    first_name: 'prénom',
    global_results: 'sommaire',
    green: 'vert',
    harassment: ' harcèlement',
    help_and_support: 'Aide et Support',
    high_risk: 'risque élevé',
    history: 'historique',
    identity_matching: "validation de l'identité",
    inactive_users: 'utilisateurs inactifs',
    individual: 'Individu',
    organization: 'Organization',
    individual_plural: 'Individus',
    integrations: 'intégrations',
    in: 'en',
    invite: "l'invitation",
    investigate: 'examiner',
    invoices: {
      invoices: 'Factures',
      date: 'Date',
      invoice_number: 'No. de facture',
      status: 'Statut',
      amount: 'Montant',
      statuses: {
        paid: 'payée',
        draft: 'brouillon',
        void: 'nul',
        open: 'ouverte',
        uncollectible: 'irrécouvrable',
      },
      last_payment: 'Dernier paiement',
      payment_amount: 'Montant du paiement',
    },
    last_name: 'nom de famille',
    last_request: 'dernière requête',
    legal: 'légal',
    less: 'moins',
    less_likely: 'moins probable',
    likely: 'probable',
    live: 'en temps réel',
    prefrence_text:
      'Activer ou désactiver EnContinu par défaut pour toutes les nouvelles validations',
    live_validation: 'EnContinu',
    live_validations: 'EnContinu',
    
    extend_search: 'Recherche étendue',
    not_trusted: 'Pas de confiance',
    location: 'Emplacement',
    month: 'mois',
    month_plural: 'mois',
    most: 'plus',
    most_likely: 'plus probable',
    name: 'nom',
    name_location: 'nom & emplacement',
    never: 'jamais',
    new: 'nouveau',
    new_feminine: 'nouvelle',
    new_validation: 'Nouvelle validation',
    news: 'nouvelles',
    next: 'suivant',
    new_instant_validation: 'Mettre à jour la validation',
    new_password: 'nouveau mot de passe',
    nick_name: 'Surnom (optionnel)',
    alternative_first_name: 'Autre Prénom (optionnel)',
    no: 'non',
    notable_themes: 'thèmes importants',
    notifications: 'notifications',
    off: 'non',
    ok: 'ok',
    old_password: 'ancien mot de passe',
    optional: 'optionnel',
    on: 'oui',
    other: 'autres',
    other_results: 'autres résultats',
    password: 'mot de passe',
    code: 'code',
    pending_consent: 'invitation en attente',
    pending_consent_plural: 'invitations en attente ',
    period_worked: ' période travaillée',
    personal_info: 'informations personnelles',
    per_month: 'par mois',
    phone_number: 'Numéro de téléphone',
    plan: 'plan',
    plans_for_every_org: 'Un plan pour toute organisation',
    plan_type: 'Plan: {{planType}}',
    plan_validation_number:
      '<span><strong>Fonctionnalités</strong> avancées</span>',
    positions: 'organisations',
    previous: 'précédent',
    pending_verification: 'en attente de vérification',
    pending_articles_verification: 'En attente de vérification des articles',
    pending_twitter_verification:'En attente de vérification de Twitter',
    new_twitter_validation:
      'Exécuter une nouvelle <br/>validation avec Twitter',
    privacy_statement: 'Déclaration de confidentialité',
    profile: 'profil',
    province: 'province',
    province_state: 'Province/État',
    red: 'rouge',
    reference_1: 'une référence',
    reference: 'référence',
    references: 'références',
    ready_for_validation: 'Prêt a valider',
    individuals_will_appear_here: 'Vos individus à valider apparaîtront ici.',
    organizations_will_appear_here:
      'Vos organisations à valider apparaîtront ici.',
    reminder: 'rappel',
    report: 'rapport',
    report_page: {
      idMatch: 'Identifiant',
      id_match: 'Identifiant',
      STRONG: 'FORT',
      WEAK: 'FAIBLE',
      article: 'Article',
      article_plural: 'Articles',
      articles: 'Articles',
      LIVE: 'EN CONTINU',
      INSTANT: 'INSTANT',
      source: 'Source',
      credible: 'CREDIBLE',
      ALL: 'TOUT',
      found_on: 'Trouvé le',
    },
    request: 'requête',
    reset_password: 'réinitialiser le mot de passe',
    create_password: 'Créer un mot de passe',
    risk_indicator: 'Indicateur de risque',
    risk: 'Risque',
    role: 'titre',
    role_name: 'Rôle ou Titre',
    role_plural: 'titres',
    row: 'ligne',
    row_plural: 'lignes',
    security_settings: 'Paramètres de sécurité',
    selected: 'sélectionné',
    select: 'sélectionner',
    settings: 'Paramètres',
    start_date: 'Date de début',
    status: 'statut',
    summary: 'résumé',
    success: 'Réussi',
    switch_language: 'english',
    talent: 'talent',
    talent_plural: 'talents',
    talent_SECURITY: 'individu',
    talent_SECURITY_plural: 'individus',
    terms_of_use: "Conditions d'utilisation",
    twitter: 'twitter',
    unavailable: 'non disponible',
    upgrade: 'sélectionner',
    user: 'utilisateur',
    user_management: 'Gestion des utilisateurs',
    user_plural: 'utilisateurs',
    user_type: 'permission',
    validation: 'validation',
    validation_plural: 'validations',
    validation_date: 'dernière date de validation',
    violence: 'violence',
    year: 'an',
    year_plural: 'ans',
    yellow: 'jaune',
    yes: 'oui',
    quantity: 'Quantité',
    pricing_details: 'Détails de facturation',
    item: 'Article',
    unit_price: 'Prix unitaire',
    amount: 'Montant',
    total: 'total',
    total_validation_period: 'Période de validation totale',
    userMessagesUnread: 'Salut, you have unread message.',
    validation_report: 'État du rapport',
    verified_estimate: {
      verifying: 'Vérifié sous peu...',
      within_hours: 'Vérifié d’ici {{hours}}h',
      within_monday: 'Vérifié avant lundi 9h',
    },
    pdf_report:{
      header:'Rapport des nouvelles en ligne pour',
      powered_by:'Alimenté par',
      view_on_twitter:'Voir sur Twitter',
      posted_on:'Publié le',
      is_subject:"est le sujet de l'action",
      not_subject:"n'est pas le sujet de l'action",
      missconduct:'Inconduite',
      found_on:'Trouvé le',
      from:'De',
      links_analysed:'Liens analisés',
      created_by: 'Rapport créé par {{name}} pour {{company}}.',      
      english: "Anglais" ,
      french: "Français",
      spanish: "Espagnol",
      portuguese: "Portugais"
    }
  },
  options: {
    role: {
      talent_acquisition: 'Acquisition de Talents',
      executive: 'VP / Exécutif',
      hr_consultant: 'Consultant(e) en RH',
      other: 'Autre',
    },
  },
  verbs: {
    accepted: 'accepté',
    add: 'ajouter',
    add_individual: "Ajouter l'individu",
    add_organization: "Ajouter l'organisation",
    add_without_company: 'Ajouter sans entreprise',
    added: 'ajouté',
    apply: 'appliquer',
    archive: 'archiver',
    archived: 'archivé',
    back_to: 'Retour à',
    cancel: 'annuler',
    changed: 'changé',
    change_access_type: "changer les types d'accès",
    change_activity_status: "changer le statut d'activité",
    remove_access: "Supprimer l'accès",
    grant_access: "Autoriser l'accès",
    change_plan: 'changer le plan',
    change_plan_capitalized: 'Modifier votre plan',
    changed_plural: 'changés',
    close: 'Fermer',
    confirmed: 'confirmée',
    contact_us: 'contactez-nous',
    continue: 'continuer',
    connect: 'Connecter',
    created_by: 'créé par',
    deactivate: 'désactiver',
    decline: 'décliner',
    declined: 'déclinée',
    declined_plural: 'déclinées',
    disable: 'désactiver',
    disconnect: 'Déconnecter',
    denied: 'refusé',
    delete: 'effacer',
    update_privacy: 'Mettre à jour la confidentialité',
    edit: 'modifier',
    enable: 'activer',
    enroll: 'inscrire',
    filter: 'filtrer',
    finalize: 'finaliser',
    finish: 'terminer',
    get_started: 'Enregistrez-vous',
    go_back: 'fermer',
    invite: 'inviter',
    in_progress: 'en cours',
    last_validated: 'dernière mise à jour',
    last_validated_on: 'Dernière mise à jour le',
    loading: 'chargement en cours',
    login: 'se connecter',
    login_with: 'se connecter avec',
    logout: 'se déconnecter',
    new_individual: 'Nouvel Individu',
    new_organization: 'Nouvelle organisation',
    pending: 'en attente',
    language: "Langue de l'article",
    published_on: 'Publié le {{date}}',
    no_publish_date: 'Aucune date de publication',
    purchase: 'acheter',
    reactivate: 'réactiver',
    refused: 'déclinée',
    remind: 'renvoyer',
    remove: 'supprimer',
    revoke: 'révoquer',
    run_validation: 'effectuer la validation',
    send: 'envoyer',
    sent: 'envoyé',
    show: 'Afficher',
    started: 'démarré',
    submit: 'soumettre',
    update: 'mettre à jour',
    updated: 'Mis à jour',
    updated_feminine: 'Mise à jour',
    upgrade_plan: 'Réhaussez votre plan',
    select_plan: 'Choisir un plan',
    upload_image: "télécharger_l'image",
    update_individual: "mettre à jour l'individu",
    update_organization: "mettre à jour l'organisation",
    update_validation: 'Mettre à jour la validation',
    validated: 'validé',
    validated_by: 'validé par',
    validated_feminine_plural: 'validées',
    validate_new_individual: 'Valider Un Individu ',
    validate_new_organization: 'Valider une nouvelle organisation',
    view: 'visionner le',
    cancel_subscription: "Annuler l'abonnement",
    add_to_plan: 'Ajouter au plan',
    finalize_purchase: "Finaliser l'achat",
    make_admin: 'Rendre admin',
    make_delegate: 'Rendre déléguer',
    unarchive: 'Désarchiver',
  },
};
