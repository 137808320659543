import Axios from 'axios';
import * as React from 'react';
import { useEffect } from 'react';
import {
  useRefreshToken,
  useSetToken,
  useSetRefreshToken,
  useSetUser,
  useToken,
} from 'utils/contexts/app-context';

export const SERVER_URL: string = `${
  process.env.REACT_APP_NODE_SERVER_URL || ''
}/app`;

const instance = Axios.create({
  baseURL: `${SERVER_URL}/`,
});

let refreshingCall: Promise<string> | null = null;

const useAPI = () => {
  // Do not use the token and user when doing 'auth' requests (like login)
  const token = useToken();
  const refreshToken = useRefreshToken();
  const setToken = useSetToken();
  const setRefreshToken = useSetRefreshToken();
  const setUser = useSetUser();

  // Function that will be called to refresh authorization
  const refreshAuthLogic = React.useCallback(async () => {
    if (refreshingCall) {
      return refreshingCall;
    }

    refreshingCall = (async () => {
      const { data } = await Axios.post(`${SERVER_URL}/auth/refresh`, {
        refresh: refreshToken,
      });
      return data.access;
    })();

    const access = await refreshingCall;

    setToken(access);
    refreshingCall = null;

    return access;
  }, [refreshToken, setToken]);

  useEffect(() => {
    const interceptor = instance.interceptors.response.use(
      response => response,
      async error => {
        const status = error.response ? error.response.status : null;

        if (status === 401) {
          const newToken = await refreshAuthLogic();
          error.config.headers.Authorization = `Bearer ${newToken}`;
          error.config.baseURL = undefined;
          try {
            return await Axios.request(error.config);
          } catch (responseError: any) {
            if (
              responseError.response &&
              responseError.response.status === 401
            ) {
              setToken(null);
              setRefreshToken(null);
              setUser(null);
            }
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      instance.interceptors.response.eject(interceptor);
    };
  }, [refreshAuthLogic, setToken, setRefreshToken, setUser]);

  React.useEffect(() => {
    instance.defaults.headers.common.Authorization =
      token == null ? '' : `Bearer ${token}`;
  }, [token]);

  return instance;
};

export default useAPI;
