// @flow
import { createSlice } from '@reduxjs/toolkit';
import { clearAll } from './global';

type State = {
    isOpen: ?boolean,
    talent: ?Talent,
    delegate: ?Delegate,
    sameCompany: ?boolean
};

/** Actions */
type OpenDrawerAction = { payload: { talent?: Talent, delegate?: Delegate, sameCompany?: boolean } };
type CloseDrawerAction = {};
type UpdateAction = { payload: { updated: Talent | Delegate } };

const drawer = createSlice({
    name:'drawer',
    slice: 'drawer',
    initialState: {
        isOpen: null,
        talent: null,
        delegate: null,
        sameCompany: true
    },
    reducers: {
        openDrawer(state: State, action: OpenDrawerAction) {
            return {
                isOpen: true,
                talent: action.payload.talent || state.talent,
                delegate: action.payload.delegate || state.delegate,
                sameCompany: action.payload.sameCompany === false ? false : true
            };
        },
        closeDrawer(state: State, action: CloseDrawerAction) {
            return {
                ...state,
                isOpen: false,
            };
        },
        clearContents(state: State) {
            return {
                ...state,
                talent: null,
                delegate: null,
            };
        },
    },
    extraReducers: {
        'talents/update': (state: State, action: UpdateAction) => {
            const { updated } = action.payload;
            const { talent } = state;
            if (talent != null && updated.id === talent.id) {
                return {
                    ...state,
                    talent: { ...talent, ...updated },
                };
            } else {
                return state;
            }
        },
        'delegates/update': (state: State, action: UpdateAction) => {
            const { updated } = action.payload;
            const { delegate } = state;
            if (delegate != null && updated.id === delegate.id) {
                return {
                    ...state,
                    delegate: { ...delegate, ...updated },
                };
            } else {
                return state;
            }
        },
        [clearAll]: (state: State) => {
            return {
                isOpen: null,
                talent: null,
                delegate: null,
            };
        },
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = drawer;
// Extract and export each action creator by name
export const { openDrawer, closeDrawer, clearContents } = actions;
// Export the reducer, either as a default or named export
export default reducer;
