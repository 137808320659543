// @flow
import * as React from 'react';

/**
 * No Profile Picture Large svg
 *
 * @param props
 * @return {XML}
 */
export default (props: { className: string }) => {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 60 60"
        >
            <defs>
                <circle id="a" cx="30" cy="30" r="30" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a" />
                </mask>
                <use fill="#D2D2DC" fillRule="nonzero" xlinkHref="#a" />
                <circle
                    cx="30"
                    cy="24"
                    r="10"
                    fill="#FFF"
                    fillRule="nonzero"
                    mask="url(#b)"
                    opacity=".75"
                />
                <circle
                    cx="30"
                    cy="60"
                    r="20"
                    fill="#FFF"
                    fillRule="nonzero"
                    mask="url(#b)"
                    opacity=".75"
                />
            </g>
        </svg>
    );
};
