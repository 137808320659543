// @flow
import { createSlice } from '@reduxjs/toolkit';
import { clearAll } from './global';

/** State */

type State = {
    all: User[],
};

/** Actions */
type SetListAction = { payload: { list: User[] } };
type UpdateAction = { payload: { updated: any } };

/** Slice */

const talents = createSlice({
    name:'admins',
    slice: 'admins',
    initialState: {
        all: [],
    },
    reducers: {
        setAllAdmins(state: State, action: SetListAction) {
            return { all: action.payload.list };
        },

        updateAdmins(state: State, action: UpdateAction) {
            const { updated } = action.payload;
            const updateList = (list: User[]) =>
                list.map(x => (x.id === updated.id ? { ...x, ...updated } : x));

            return { all: updateList(state.all) };
        },
    },
    extraReducers: {
        [clearAll]: (state: State) => {
            return { all: [] };
        },
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = talents;
// Extract and export each action creator by name
export const { setAllAdmins, updateAdmins } = actions;
// Export the reducer, either as a default or named export
export default reducer;
