// @flow
import { createSlice } from '@reduxjs/toolkit';
import { clearAll } from './global';

/** State */

type State = {
    all: Talent[],
    dashboard: {
        all: Talent[],
        pending: Talent[],
        declined: Talent[],
        count: number,
    },
    search: {
        sameCompany: Talent[],
        otherCompanies: Talent[],
    },
    report: Talent,
};

const initialAll = null;
const initialRecent = { all: null, pending: null, declined: null, count: null };
const initialSearch = { sameCompany: [], otherCompanies: [] };
const initialReport = {};

/** Actions */
type SetListAction = { payload: { list: Talent[] } };
type SetCountAction = { payload: { count: number } };
type UpdateAction = { payload: { updated: any } };
type SetSingleAction = { payload: { talent: any } };

/** Slice */

const talents = createSlice({
    name:'talents',
    slice: 'talents',
    initialState: {
        all: initialAll,
        dashboard: initialRecent,
        search: initialSearch,
        report: initialReport,
    },
    reducers: {
        /** all */
        setAll(state: State, action: SetListAction) {
            return { ...state, all: action.payload.list };
        },
        /** dashboard */
        setDashboardTalents(state: State, action: SetListAction) {
            return { ...state, dashboard: { ...state.dashboard, all: action.payload.list } };
        },
        setDashboardPending(state: State, action: SetListAction) {
            return { ...state, dashboard: { ...state.dashboard, pending: action.payload.list } };
        },
        setDashboardDeclined(state: State, action: SetListAction) {
            return { ...state, dashboard: { ...state.dashboard, declined: action.payload.list } };
        },
        setDashboardCount(state: State, action: SetCountAction) {
            return { ...state, dashboard: { ...state.dashboard, count: action.payload.count } };
        },
        /** search */
        setSearchSameCompany(state: State, action: SetListAction) {
            return { ...state, search: { ...state.search, sameCompany: action.payload.list } };
        },
        setSearchOtherCompanies(state: State, action: SetListAction) {
            return { ...state, search: { ...state.search, otherCompanies: action.payload.list } };
        },
        /** report */
        setReport(state: State, action: SetSingleAction) {
            return { ...state, report: action.payload.talent };
        },

        /** every list */
        update(state: State, action: UpdateAction) {
            const { updated } = action.payload;
            const updateList = (list: Talent[] | null) =>
                list ? list.map(x => (x.id === updated.id ? { ...x, ...updated } : x)) : [];

            return {
                all: updateList(state.all),
                dashboard: {
                    all: updateList(state.dashboard.all),
                    pending: updateList(state.dashboard.pending),
                    declined: updateList(state.dashboard.declined),
                    count: state.dashboard.count,
                },
                search: {
                    sameCompany: updateList(state.search.sameCompany),
                    otherCompanies: updateList(state.search.otherCompanies),
                },
                report: state.report.id === updated.id
                    ? { ...state.report, ...updated }
                    : state.report,
            };
        },
        remove(state: State, action: SetSingleAction) {
            const { talent } = action.payload;
            const removeFromList = (list: Talent[]) => list.filter(x => x.id !== talent.id);

            return {
                all: removeFromList(state.all),
                dashboard: {
                    all: removeFromList(state.dashboard.all),
                    pending: removeFromList(state.dashboard.pending),
                    declined: removeFromList(state.dashboard.declined),
                    count: state.dashboard.count,
                },
                search: {
                    sameCompany: removeFromList(state.search.sameCompany),
                    otherCompanies: removeFromList(state.search.otherCompanies),
                },
                report: state.report.id === talent.id
                    ? {}
                    : state.report,
            }
        }
    },
    extraReducers: {
        [clearAll]: (state: State) => {
            return {
                all: initialAll,
                dashboard: initialRecent,
                search: initialSearch,
            };
        },
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = talents;
// Extract and export each action creator by name
export const {
    setAll,
    setDashboardTalents,
    setDashboardPending,
    setDashboardDeclined,
    setDashboardCount,
    setSearchSameCompany,
    setSearchOtherCompanies,
    setReport,
    update,
    remove,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;
