// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Motion, spring } from 'react-motion';
import styles from './style.module.scss';
import useWindowDimensions from '../../hooks/useWindowDimensions.js';

type Props = {
  children: ?Node,
  isVisible: boolean,
  onDismiss?: () => void,
  fullScreenOnMobile?: boolean,
  preventAnimation?: boolean,
};

const stopPropagation = (event: SyntheticMouseEvent<HTMLDivElement>): void => {
  event.stopPropagation();
};

const Modal = ({
  children,
  isVisible,
  onDismiss = _.noop,
  fullScreenOnMobile,
  preventAnimation,
}: Props) => {
  // Keeps track of whether mouse was clicked inside the modal
  const [mouseDown, setMouseDown] = useState(false);
  // Returns a DOM element or undefined
  const root = document.getElementById('root');
  const { width } = useWindowDimensions();

  if (isVisible && root) {
    return ReactDOM.createPortal(
      <div>
        {width <= 700 && !preventAnimation ? (
          <Motion
            defaultStyle={{ translateY: fullScreenOnMobile ? 100 : 0 }}
            style={{ translateY: spring(0) }}
          >
            {iStyles => (
              <div className={styles.modal_backdrop} onClick={onDismiss}>
                <div
                  className={`${
                    fullScreenOnMobile
                      ? styles.modal_page_fullscreen_mobile
                      : styles.modal_page
                  }`}
                  style={{ transform: `translateY(${iStyles.translateY}vh)` }}
                  onClick={stopPropagation}
                >
                  {children}
                </div>
              </div>
            )}
          </Motion>
        ) : (
          <div
            className={styles.modal_backdrop}
            onMouseUp={!mouseDown ? onDismiss : () => setMouseDown(false)}
          >
            <div
              className={`${
                fullScreenOnMobile
                  ? styles.modal_page_fullscreen_mobile
                  : styles.modal_page
              }`}
              onMouseDown={() => setMouseDown(true)}
              onMouseUp={e => {
                stopPropagation(e);
                setMouseDown(false);
              }}
            >
              {children}
            </div>
          </div>
        )}
      </div>,
      root
    );
  }
  return null;
};

export default Modal;
