// @flow
import * as React from 'react';

/**
 * Talent svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="15" viewBox="0 0 24 15">
            <g fill="none" fillRule="nonzero">
                <path fill="#FFF" stroke="#1083C6" d="M2.5.5v11h19V.5h-19z"/>
                <path fill="#1083C6" d="M0 14h24v1H0z"/>
            </g>
        </svg>
    );
};
