// @flow
import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import styles from './styles.module.scss';

type Props = {
  pages: Array<{
    name: string,
    route: string,
  }>,
  location: any,
};

const getLinkStyle = (page, selected) => {
  if (page.route === selected) {
    return `${styles.nav_link} ${styles.selected_link}`;
  }
  return `normal_text ${styles.nav_link}`;
};

const PageSelector = ({ pages, location }: Props) => {
  return (
    <div className={styles.container}>
      {pages.map(page => (
        <div className={styles.link_holder} key={page.name}>
          <Link to={page.route}>
            <p className={getLinkStyle(page, location.pathname)}>
              {_.upperFirst(page.name)}
            </p>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default withRouter(PageSelector);
