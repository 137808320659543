import { Dispatch, Action } from 'redux';
import axios from 'axios';
import { User } from '../reducers/users'
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { NotificationManager } from 'react-notifications';

// Action types
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const TRANSFER_OWNERSHIP_REQUEST = 'TRANSFER_OWNERSHIP_REQUEST';
export const TRANSFER_OWNERSHIP_SUCCESS = 'TRANSFER_OWNERSHIP_SUCCESS';
export const TRANSFER_OWNERSHIP_FAILURE = 'TRANSFER_OWNERSHIP_FAILURE';


interface AddUserProps {
    team_name: string;
    access_type: 'PRIVATE' | 'PUBLIC';
    validation_limit: number;
}

interface GetUsersRequest extends Action<typeof GET_USERS_REQUEST> { }
interface GetUsersSuccess extends Action<typeof GET_USERS_SUCCESS> {
    payload: User[]
}
interface GetUsersFailure extends Action<typeof GET_USERS_FAILURE> {
    payload: string
}

interface AddUserRequest extends Action<typeof ADD_USER_REQUEST> { }
interface AddUserSuccess extends Action<typeof ADD_USER_SUCCESS> { }
interface AddUserFailure extends Action<typeof ADD_USER_FAILURE> {
    payload: string
}

interface EditUserRequest extends Action<typeof EDIT_USER_REQUEST> { }
interface EditUserSuccess extends Action<typeof EDIT_USER_SUCCESS> { }
interface EditUserFailure extends Action<typeof EDIT_USER_FAILURE> {
    payload: string
}

interface TransferOwnershipRequest extends Action<typeof TRANSFER_OWNERSHIP_REQUEST> { }
interface TransferOwnershipSuccess extends Action<typeof TRANSFER_OWNERSHIP_SUCCESS> { }
interface TransferOwnershipFailure extends Action<typeof TRANSFER_OWNERSHIP_FAILURE> {
    payload: string
}

export type ActionTypes = GetUsersRequest | GetUsersSuccess | GetUsersFailure
    | AddUserRequest | AddUserSuccess | AddUserFailure
    | EditUserRequest | EditUserSuccess | EditUserFailure
    | TransferOwnershipRequest | TransferOwnershipSuccess | TransferOwnershipFailure


// Action creators
const getUsersRequest = (): GetUsersRequest => ({ type: GET_USERS_REQUEST });
const getUsersSuccess = (data: User[]): GetUsersSuccess => ({ type: GET_USERS_SUCCESS, payload: data });
const getUsersFailure = (error: string): GetUsersFailure => ({ type: GET_USERS_FAILURE, payload: error });

const addUserRequest = (): AddUserRequest => ({ type: ADD_USER_REQUEST });
const addUserSuccess = (): AddUserSuccess => ({ type: ADD_USER_SUCCESS });
const addUserFailure = (error: string): AddUserFailure => ({ type: ADD_USER_FAILURE, payload: error });

const editUserRequest = (): EditUserRequest => ({ type: EDIT_USER_REQUEST });
const editUserSuccess = (): EditUserSuccess => ({ type: EDIT_USER_SUCCESS });
const editUserFailure = (error: string): EditUserFailure => ({ type: EDIT_USER_FAILURE, payload: error });


const transferOwnershipRequest = (): TransferOwnershipRequest => ({ type: TRANSFER_OWNERSHIP_REQUEST });
const transferOwnershipSuccess = (): TransferOwnershipSuccess => ({ type: TRANSFER_OWNERSHIP_SUCCESS });
const transferOwnershipFailure = (error: string): TransferOwnershipFailure => ({ type: TRANSFER_OWNERSHIP_FAILURE, payload: error });

// Async action creators
const getUsers = () => {
    const token = window.localStorage.getItem('token')
    return async (dispatch: Dispatch) => {
        try {
            dispatch(getUsersRequest());
            const response = await axios.get(
                `${process.env.REACT_APP_NODE_SERVER_URL}/app/users/all`
                , {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            dispatch(getUsersSuccess(response.data.users || []))

        } catch (error: any) {
            console.log('error', error)
            NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
            dispatch(getUsersFailure(error.message));
        }
    };
};

const addUser = (data: AddUserProps, resetCloseForm: () => void): ThunkAction<Promise<void>, any, any, ActionTypes> => {
    const token = window.localStorage.getItem('token');
    return async (dispatch: ThunkDispatch<any, any, ActionTypes>) => {
        try {
            dispatch(addUserRequest());
            await axios.post(
                // `${process.env.REACT_APP_NODE_SERVER_URL}/app/users`
                `${process.env.REACT_APP_NODE_SERVER_URL}/app/users/invite-user`
                ,
                { ...data },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(addUserSuccess());
            dispatch(getUsers()); // Call the getUsers action after adding a team
            resetCloseForm();
        } catch (error: any) {
            console.log('error', error);
            NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
            dispatch(addUserFailure(error.message));
        }
    };
};

const editUser = (data: AddUserProps, id: number, resetCloseForm: () => void): ThunkAction<Promise<void>, any, any, ActionTypes> => {
    const token = window.localStorage.getItem('token');
    return async (dispatch: ThunkDispatch<any, any, ActionTypes>) => {
        try {
            dispatch(editUserRequest());
            await axios.put(
                // `${process.env.REACT_APP_NODE_SERVER_URL}/app/users/${id}`
                `${process.env.REACT_APP_NODE_SERVER_URL}/app/users/update/${id}`
                ,
                { ...data },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(editUserSuccess());
            dispatch(getUsers()); // Call the Users action after edditing a team
            resetCloseForm();
        } catch (error: any) {
            console.log('error', error);
            NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
            dispatch(editUserFailure(error.message));
        }
    };
};

const transferOwnership =( id: number, resetCloseForm: () => void): ThunkAction<Promise<void>, any, any, ActionTypes> => {
    const token = window.localStorage.getItem('token');
    return async (dispatch: ThunkDispatch<any, any, ActionTypes>) => {
        try {
            dispatch(transferOwnershipRequest());
            await axios.put(
                `${process.env.REACT_APP_NODE_SERVER_URL}/app/me/${id}/transfer-ownership`
                ,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(transferOwnershipSuccess());
            resetCloseForm();
           // force a reload!
           window.location.reload();
        } catch (error: any) {
            console.log('error', error);
            NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
            dispatch(transferOwnershipFailure(error.message));
        }
    };
};
const actions = {
    getUsers,
    addUser,
    editUser,
    transferOwnership
}

export default actions
