// @flow
import * as React from "react";

/**
 * Eye svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="14"
            viewBox="0 0 22 14"
        >
            <path
                fill="#CDCDCD"
                fillRule="nonzero"
                d="M21.832 6.445C21.656 6.182 17.461 0 11 0 4.506 0 .34 6.185.166 6.448a.998.998 0 0 0 .002 1.106C.344 7.818 4.539 14 11 14c6.428 0 10.653-6.179 10.83-6.442a1 1 0 0 0 .002-1.113zM11 12c-4.35 0-7.635-3.596-8.753-5.002.658-.832 2.075-2.425 4.024-3.59A4.903 4.903 0 0 0 6 5c0 2.757 2.243 5 5 5s5-2.243 5-5a4.9 4.9 0 0 0-.268-1.582c1.94 1.164 3.357 2.75 4.018 3.581C18.623 8.407 15.324 12 11 12z"
            />
        </svg>
    );
};
