import * as React from 'react';
import axios from 'axios';
import { Company, CompleteUser, Permissions } from 'types';
import Targets from 'utils/targets';
import { useHistory } from 'react-router-dom';
import * as LocalStorage from '../local-storage';

interface Props {
  initialToken: string;
  initialRefreshToken: string;
  target: Targets;
  children: any;
}

type Context = {
  token: string | null;
  setToken: (token: string | null) => void;
  refreshToken: string | null;
  setRefreshToken: (token: string | null) => void;
  user: CompleteUser | null;
  setUser: (user: CompleteUser | null) => void;
  updateUser: () => Promise<any> | null;
  target: Targets;
};

const AppContext = React.createContext<Context>({
  token: '',
  setToken: t => t,
  refreshToken: '',
  setRefreshToken: t => t,
  user: null,
  setUser: u => u,
  updateUser: () => null,
  target: Targets.Security,
});
const { useState, useContext } = React;

/**
 * Custom hooks for contexts
 */

export const useSetToken = () => {
  const { setToken } = useContext<Context>(AppContext);
  return setToken;
};

export const useToken = () => {
  const { token } = useContext(AppContext);
  return token;
};

export const useRefreshToken = () => {
  const { refreshToken } = useContext(AppContext);
  return refreshToken;
};

export const useSetRefreshToken = () => {
  const { setRefreshToken } = useContext(AppContext);
  return setRefreshToken;
};

export const useSetUser = () => {
  const { setUser } = useContext(AppContext);
  return setUser;
};

export const useUser = () => {
  const { user } = useContext(AppContext);
  return user;
};

export const useUpdateUser = () => {
  const { updateUser } = useContext(AppContext);
  return updateUser;
};

export const usePermissions = (): Permissions | null => {
  const { user } = useContext(AppContext);

  return user && user.employments?.length > 0
    ? user.employments[0].permissions || {}
    : {};
};

export const useCompany = (): Company | null => {
  const { user } = useContext(AppContext);

  return user?.company || null;
};

export const useTarget = (): Targets => {
  const { target } = useContext(AppContext);

  return target;
};

let refreshing = false;

export const AppProvider = ({
  initialToken,
  initialRefreshToken,
  target,
  children,
}: Props) => {
  const [token, setToken] = useState<string | null>(initialToken);
  const [refreshToken, setRefreshToken] = useState<string | null>(
    initialRefreshToken
  );
  const [user, setUser] = useState<CompleteUser | null>(null);
  const history = useHistory();

  // React.useEffect(() => {
  //   // if (
  //   //   user?.company &&
  //   //   user?.company?.product_type?.toLowerCase() !== target
  //   // ) {
  //   //   history.push(`/security/app`);
  //   // }
  // }, [history, target, user]);

  const fetchUser = async (accessToken: string) => {
    const { data } = await axios.get('/me', {
      headers: { Authorization: `Bearer ${accessToken}` },
    });


const isAdmin = Boolean(data.user.privilegeLevel === 'ADMIN' || data.user.privilegeLevel === 'OWNER')
    setUser({
      ...data.user,
      isAdmin: isAdmin ,
      prefrences: data.user.prefrences,
      levAccess: true,
      company: data.company,
      isOwner: data.user.isOwner,
      accessLevel: data.user.privilegeLevel,
      privacy_enabled:data.team.allowsPrivacy,
      team_id: data.team.uuid,
      team_name: data.team.name,
    });
  };

  const refresh = React.useCallback(async () => {
    try {
      const { data: refreshTokenData } = await axios.post('/auth/refresh', {
        refresh: refreshToken,
      });
      setToken(refreshTokenData.access);
    } catch {
      setRefreshToken(null);
      setToken(null);
    }
  }, [refreshToken, setToken, setRefreshToken]);

  const updateUser = React.useCallback(async () => {
    if (!token || !refreshToken) {
      return;
    }

    try {
      await fetchUser(token);
    } catch (err: any) {
      if (err.response != null && err.response.status === 401 && !refreshing) {
        refreshing = true;

        await refresh();
        await fetchUser(token);
      } else {
        setToken(null);
        setRefreshToken(null);
      }
    }
  }, [token, refreshToken, refresh, setToken]);

  /** When the `token` is cleared, the `user` should be removed. */
  React.useEffect(() => {
    LocalStorage.setToken(token);
    LocalStorage.setRefreshToken(refreshToken);
    if (!token || !refreshToken) {
      setUser(null);
    } else {
      updateUser();
    }
  }, [token, refreshToken, setUser, updateUser]);

  return (
    <AppContext.Provider
      value={{
        token,
        setToken,
        user,
        setUser,
        updateUser,
        refreshToken,
        setRefreshToken,
        target,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
