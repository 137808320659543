// @flow

import React from 'react';
import type { Node } from 'react';
import styles from './styles.module.scss';
import Modal from 'components/modal';

type Props = {
    isVisible: boolean,
    title: string | Node,
    subtitle?: string,
    description: string,
    actionButtons?: Node,
    children?: (onDismiss: () => void) => Node,
    onDismiss?: () => void,
};

const PopUp = ({ isVisible, title, subtitle, description, children, onDismiss, actionButtons }: Props) => {
    return (
        <Modal isVisible={isVisible} onDismiss={onDismiss}>
            <div className={styles.modal_contents}>
                {typeof title === 'string' ? <p className={styles.title}>{title}</p> : title}

                <p className={styles.description}>{description}</p>

                {children}

                {actionButtons && <div className={styles.btn_actions_container}>
                    {actionButtons}
                </div>}
            </div>
        </Modal>
    )
};

export default PopUp;
