import { amber, red, grey } from '@mui/material/colors';
import {
  createTheme,
  ThemeProvider as MThemeProvider,
  PaletteOptions,
  ThemeOptions,
} from '@mui/material/styles';
import React from 'react';

export interface CustomPaletteOptions extends PaletteOptions {
  primary_darker: {
    main: string;
    secondary: string;
  };
}
interface CustomThemeOptions extends ThemeOptions {
  palette: CustomPaletteOptions;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#1083c6',
    },
    secondary: {
      main: '#00b6a5',
    },
    primary_darker: {
      main: '#08527e',
      secondary: '#0d78b7',
    },
    error: {
      main: red.A200,
    },
    warning: {
      main: amber.A700,
    },
    twitter: {
      main: '#00acee',
    },
    light: {
      primary: 'rgba(16, 131, 198, 0.12)',
      secondary: 'rgba(0, 182, 165, 0.12)',
      error: 'rgba(255, 82, 82, 0.12)',
      warning: 'rgba(255, 171, 0, 0.12)',
      default: grey[200] as string,
      twitter: 'rgba(0, 172, 238, 0.12)',
    },
    background: {
      default: '#ebedef',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: [
      'Maven Pro',
      '-apple-system',
      'system-ui',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: 32,
    },
    h2: {
      fontSize: 28,
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 18,
    },
    h5: {
      fontSize: 16,
      lineHeight: 1.2,
      fontWeight: 500,
    },
    h6: {
      fontSize: 15,
      lineHeight: 1.2,
      fontWeight: 500,
    },
    body1: {
      fontSize: 14,
      lineHeight: 1.2,
    },
    body2: {
      fontSize: 13,
      lineHeight: 1.3,
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          fontWeight: '500 !important',
          color: '#1083c6 !important',
          backgroundColor: 'rgba(16, 131, 198, 0.12) !important',
        },
        clickableColorPrimary: {
          fontWeight: '500 !important',
          color: '#1083c6',
          backgroundColor: 'rgba(16, 131, 198, 0.12) !important',
          '&:focus': {
            backgroundColor: 'rgba(16, 131, 198, 0.12) !important',
          },
          '&:hover': {
            backgroundColor: 'rgba(16, 131, 198, 0.12) !important',
          },
        },
      }
    },

    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: 'rgba(16, 131, 198, 0.92)',
        },
        tooltip: {
          fontWeight: 'normal',
          padding: 12,
          fontSize: 16,
          backgroundColor: 'rgba(16, 131, 198, 0.92)',
        },
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorAction: {
          color: amber.A700,
        },
      }
    },
  },
} as CustomThemeOptions);

const ThemeProvider: React.FC<IProps> = ({ children }) => {
  return <MThemeProvider theme={theme}>{children}</MThemeProvider>;
};

interface IProps {
  children: React.ReactNode;
}

export default ThemeProvider;
