// @flow
import { createSlice } from '@reduxjs/toolkit';

type State = {
    distanceToBottom: ?number,
};

const windowSlice = createSlice({
    name:'window',
    slice: 'window',
    initialState: {
        distanceToBottom: null,
    },
    reducers: {
        setDistanceToBottom(state: State, action) {
            return { distanceToBottom: action.payload.distance };
        },
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = windowSlice;
// Extract and export each action creator by name
export const { setDistanceToBottom } = actions;
// Export the reducer, either as a default or named export
export default reducer;
