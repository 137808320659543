import { Dispatch, Action } from 'redux';
import axios from 'axios';
import { Team } from '../reducers/teams'
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { NotificationManager } from 'react-notifications';


// Action types
export const GET_TEAMS_REQUEST = 'GET_TEAMS_REQUEST';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_FAILURE = 'GET_TEAMS_FAILURE';

export const GET_TEAM_REQUEST = 'GET_TEAM_REQUEST';
export const GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS';
export const GET_TEAM_FAILURE = 'GET_TEAM_FAILURE';

export const ADD_TEAM_REQUEST = 'ADD_TEAM_REQUEST';
export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS';
export const ADD_TEAM_FAILURE = 'ADD_TEAM_FAILURE';

export const EDIT_TEAM_REQUEST = 'EDIT_TEAM_REQUEST';
export const EDIT_TEAM_SUCCESS = 'EDIT_TEAM_SUCCESS';
export const EDIT_TEAM_FAILURE = 'EDIT_TEAM_FAILURE';

export const DELETE_TEAM_REQUEST = 'DELETE_TEAM_REQUEST';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_FAILURE = 'DELETE_TEAM_FAILURE';

interface AddTeamProps {
  team_name: string;
  access_type: 'PRIVATE' | 'PUBLIC';
  validation_limit: number;
}

interface DeleteTeamProps {
  target: string | number;
}

interface GetTeamsRequest extends Action<typeof GET_TEAMS_REQUEST> { }
interface GetTeamsSuccess extends Action<typeof GET_TEAMS_SUCCESS> {
  payload: Team[]
}
interface GetTeamsFailure extends Action<typeof GET_TEAMS_FAILURE> {
  payload: string
}

interface GetTeamRequest extends Action<typeof GET_TEAM_REQUEST> { }
interface GetTeamSuccess extends Action<typeof GET_TEAM_SUCCESS> {
  payload: Team
}
interface GetTeamFailure extends Action<typeof GET_TEAM_FAILURE> {
  payload: string
}

interface AddTeamRequest extends Action<typeof ADD_TEAM_REQUEST> { }
interface AddTeamSuccess extends Action<typeof ADD_TEAM_SUCCESS> { }
interface AddTeamFailure extends Action<typeof ADD_TEAM_FAILURE> {
  payload: string
}

interface EditTeamRequest extends Action<typeof EDIT_TEAM_REQUEST> { }
interface EditTeamSuccess extends Action<typeof EDIT_TEAM_SUCCESS> { }
interface EditTeamFailure extends Action<typeof EDIT_TEAM_FAILURE> {
  payload: string
}

interface DeleteTeamRequest extends Action<typeof DELETE_TEAM_REQUEST> { }
interface DeleteTeamSuccess extends Action<typeof DELETE_TEAM_SUCCESS> { }
interface DeleteTeamFailure extends Action<typeof DELETE_TEAM_FAILURE> {
  payload: string
}

export type ActionTypes = GetTeamsRequest | GetTeamsSuccess | GetTeamsFailure
  | GetTeamRequest | GetTeamSuccess | GetTeamFailure
  | AddTeamRequest | AddTeamSuccess | AddTeamFailure
  | EditTeamRequest | EditTeamSuccess | EditTeamFailure
  | DeleteTeamRequest | DeleteTeamSuccess | DeleteTeamFailure

// Action creators
const getTeamsRequest = (): GetTeamsRequest => ({ type: GET_TEAMS_REQUEST });
const getTeamsSuccess = (data: Team[]): GetTeamsSuccess => ({ type: GET_TEAMS_SUCCESS, payload: data });
const getTeamsFailure = (error: string): GetTeamsFailure => ({ type: GET_TEAMS_FAILURE, payload: error });

const getTeamRequest = (): GetTeamRequest => ({ type: GET_TEAM_REQUEST });
const getTeamSuccess = (data: Team): GetTeamSuccess => ({ type: GET_TEAM_SUCCESS, payload: data });
const getTeamFailure = (error: string): GetTeamFailure => ({ type: GET_TEAM_FAILURE, payload: error });

const addTeamRequest = (): AddTeamRequest => ({ type: ADD_TEAM_REQUEST });
const addTeamSuccess = (): AddTeamSuccess => ({ type: ADD_TEAM_SUCCESS });
const addTeamFailure = (error: string): AddTeamFailure => ({ type: ADD_TEAM_FAILURE, payload: error });

const editTeamRequest = (): EditTeamRequest => ({ type: EDIT_TEAM_REQUEST });
const editTeamSuccess = (): EditTeamSuccess => ({ type: EDIT_TEAM_SUCCESS });
const editTeamFailure = (error: string): EditTeamFailure => ({ type: EDIT_TEAM_FAILURE, payload: error });

const deleteTeamRequest = (): DeleteTeamRequest => ({ type: DELETE_TEAM_REQUEST });
const deleteTeamSuccess = (): DeleteTeamSuccess => ({ type: DELETE_TEAM_SUCCESS });
const deleteTeamFailure = (error: string): DeleteTeamFailure => ({ type: DELETE_TEAM_FAILURE, payload: error });


// Async action creators
const getTeams = () => {
  const token = window.localStorage.getItem('token')
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getTeamsRequest());
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams/all`
        , {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      dispatch(getTeamsSuccess(response.data.teams || []))

    } catch (error: any) {
      console.log('error', error)
      NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
      dispatch(getTeamsFailure(error.message));
    }
  };
};

const getTeam = (uuid: string) => {
  const token = window.localStorage.getItem('token')
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getTeamRequest());
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams/${uuid}/team`
        , {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      dispatch(getTeamSuccess(response.data?.team || {}))

    } catch (error: any) {
      console.log('error', error)
      NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
      dispatch(getTeamFailure(error.message));
    }
  };
};

const addTeam = (data: AddTeamProps, resetCloseForm: () => void): ThunkAction<Promise<void>, any, any, ActionTypes> => {
  const token = window.localStorage.getItem('token');
  return async (dispatch: ThunkDispatch<any, any, ActionTypes>) => {
    try {
      dispatch(addTeamRequest());
      await axios.post(
        // `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams`
        `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams/create`
        ,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(addTeamSuccess());
      dispatch(getTeams()); // Call the getTeams action after adding a team
      resetCloseForm();
    } catch (error: any) {
      console.log('error', error);
      NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
      dispatch(addTeamFailure(error.message));
    }
  };
};

const editTeam = (data: AddTeamProps, id: number, resetCloseForm: () => void): ThunkAction<Promise<void>, any, any, ActionTypes> => {
  const token = window.localStorage.getItem('token');
  return async (dispatch: ThunkDispatch<any, any, ActionTypes>) => {
    try {
      dispatch(editTeamRequest());
      await axios.put(
        // `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams/${id}`
        `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams/update/${id}`
        ,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(editTeamSuccess());
      dispatch(getTeams()); // Call the getTeams action after adding a team
      resetCloseForm();
    } catch (error: any) {
      console.log('error', error);
      NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
      dispatch(editTeamFailure(error.message));
    }
  };
};

const deleteTeamWithMove = (id: number, data: DeleteTeamProps, resetCloseForm: () => void): ThunkAction<Promise<void>, any, any, ActionTypes> => {
  const token = window.localStorage.getItem('token');
  return async (dispatch: ThunkDispatch<any, any, ActionTypes>) => {
    try {
      dispatch(deleteTeamRequest());
      await axios.put(
        // `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams/${id}`
        `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams/${id}/delete-and-move-team`,
          data
        ,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(deleteTeamSuccess());
      dispatch(getTeams()); // Call the getTeams action after adding a team
      resetCloseForm();
    } catch (error: any) {
      console.log('error', error);
      NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
      dispatch(deleteTeamFailure(error.message));
    }
  };
};

const permanentlyDeleteTeam = ( id: number, resetCloseForm: () => void): ThunkAction<Promise<void>, any, any, ActionTypes> => {
  const token = window.localStorage.getItem('token');
  return async (dispatch: ThunkDispatch<any, any, ActionTypes>) => {
    try {
      dispatch(deleteTeamRequest());
      await axios.delete(
        // `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams/${id}`
        `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams/${id}`
        ,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(deleteTeamSuccess());
      dispatch(getTeams()); // Call the getTeams action after deleteing a team
      resetCloseForm();
    } catch (error: any) {
      console.log('error', error);
      NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
      dispatch(deleteTeamFailure(error.message));
    }
  };
};


const actions = {
  getTeams,
  addTeam,
  editTeam,
  getTeam,
  deleteTeamWithMove,
  permanentlyDeleteTeam
}

export default actions
