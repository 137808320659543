const TOKEN_KEY = 'token';
const REFRESH_TOKEN_KEY = 'refresh';
const REMEMBER_ME_TOKEN_KEY = 'remember_me';

/**
 * Set the user token
 *
 * @param token
 */
export const setToken = (token: string | null) => {
  if (!token) {
    localStorage.removeItem(TOKEN_KEY);
  } else {
    localStorage.setItem(TOKEN_KEY, token);
  }
};

/**
 * Get the user token
 */
export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const setRefreshToken = (token: string | null) => {
  if (!token) {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  } else {
    localStorage.setItem(REFRESH_TOKEN_KEY, token);
  }
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const setRememberMeToken = (token: string | null) => {
  if (!token) {
    localStorage.removeItem(REMEMBER_ME_TOKEN_KEY);
  } else {
    localStorage.setItem(REMEMBER_ME_TOKEN_KEY, token);
  }
};

export const getRememberMeToken = () => {
  return localStorage.getItem(REMEMBER_ME_TOKEN_KEY);
};
