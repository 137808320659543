// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ImagePlaceholder from '../../../components/image-placeholder';
import { Apartment } from '@mui/icons-material';
import styles from './styles.module.scss';

type Props = {
  results: Talent[],
  index: number,
  value: string,
  showAll: boolean,
  onSubmit: (e: any) => void,
  onShowAll: () => void,
};

const Suggestions = ({
  results,
  index,
  onSubmit,
  value,
  showAll,
  onShowAll,
}: Props) => {
  const { t } = useTranslation();

  const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);

  const showAllRowStyles = [
    results.length
      ? styles.search_results_show_all_wrapper
      : styles.search_results_show_all_wrapper_alone,
  ];
  if (index === results.length) {
    showAllRowStyles.push(styles.active);
  }

  React.useEffect(() => {
    const handleKeyDown = e => {
      if (e.key === 'Enter') {
        if (results.length > 0) {
          if (hoveredIndex !== null) {
            onSubmit(results[hoveredIndex]);
          } else {
            onSubmit(results[index]);
          }
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [results, index, hoveredIndex, onSubmit]);

  return (
    <div className={styles.search_results}>
      {results.map((r, i) => {
        const rowStyles = [styles.search_result_row];
        if (index === i || hoveredIndex === i) {
          rowStyles.push(styles.active);
        }

        const handleClick = () => onSubmit(r);
        const handleMouseEnter = () => setHoveredIndex(i);
        const handleMouseLeave = () => setHoveredIndex(null);

        return (
          <div
            className={rowStyles.join(' ')}
            key={r.uuid}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className={styles.search_result_image_holder}>
              {r.validation.type === 'LEGAL_ENTITY' ? (
                <div
                  style={{
                    marginLeft: '5px',
                    borderRadius: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '30px',
                    width: '30px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: '#E1E1E1',
                    background: 'white',
                  }}
                >
                  <Apartment style={{ color: '#908E8C' }} />
                </div>
              ) : (
                <ImagePlaceholder src={r?.user?.picture} alt="search picture" />
              )}
            </div>
            <span className={styles.search_result_text}>
              {r.validation.type === 'LEGAL_ENTITY'
                ? r.name
                : `${r.firstName} ${r.lastName}`}
            </span>
            <span className={styles.search_result_email}>
              {r.validation.isArchived ? ' [ARCHIVED]' : null}
            </span>
          </div>
        );
      })}
      {value && showAll && (
        <div className={showAllRowStyles.join(' ')} onClick={onShowAll}>
          <span className={styles.search_results_show_all}>
            {t('sentences:search_show_all', { value })}
          </span>
        </div>
      )}
    </div>
  );
};

export default Suggestions;
