// @flow
import * as React from 'react';

/**
 * BillingInfo svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18">
            <g fill="none" fillRule="nonzero">
                <path fill="#FFF" stroke="#1083C6" d="M.5.5v17h10.289l3.711-3.696V.5H.5z"/>
                <path fill="#1083C6" d="M10 13h1v4h-1z"/>
                <path fill="#1083C6" d="M10 13h5v1h-5zM6 4h4v1H6zM6 7h3v1H6zM5 5h1v2H5zM9 8h1v2H9z"/>
                <path fill="#1083C6" d="M7 3h1v9H7z"/>
                <path fill="#1083C6" d="M5 10h4v1H5z"/>
                <path fill="#ECF5FB" d="M9 10h1v1H9zM9 7h1v1H9zM5 7h1v1H5zM5 4h1v1H5z"/>
            </g>
        </svg>
    );
};
