import { Dispatch, Action } from 'redux';
import axios from 'axios';
import { Validation } from 'entities/validation';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { NotificationManager } from 'react-notifications';


// Action types
export const GET_VALIDATIONS_REQUEST = 'GET_VALIDATIONS_REQUEST';
export const GET_VALIDATIONS_SUCCESS = 'GET_VALIDATIONS_SUCCESS';
export const GET_VALIDATIONS_FAILURE = 'GET_VALIDATIONS_FAILURE';

export const GET_VALIDATION_REQUEST = 'GET_VALIDATION_REQUEST';
export const GET_VALIDATION_SUCCESS = 'GET_VALIDATION_SUCCESS';
export const GET_VALIDATION_FAILURE = 'GET_VALIDATION_FAILURE';

export const ADD_VALIDATION_REQUEST = 'ADD_VALIDATION_REQUEST';
export const ADD_VALIDATION_SUCCESS = 'ADD_VALIDATION_SUCCESS';
export const ADD_VALIDATION_FAILURE = 'ADD_VALIDATION_FAILURE';

export const EDIT_VALIDATION_REQUEST = 'EDIT_VALIDATION_REQUEST';
export const EDIT_VALIDATION_SUCCESS = 'EDIT_VALIDATION_SUCCESS';
export const EDIT_VALIDATION_FAILURE = 'EDIT_VALIDATION_FAILURE';

export const DELETE_VALIDATION_REQUEST = 'DELETE_VALIDATION_REQUEST';
export const DELETE_VALIDATION_SUCCESS = 'DELETE_VALIDATION_SUCCESS';
export const DELETE_VALIDATION_FAILURE = 'DELETE_VALIDATION_FAILURE';

interface AddValidationProps {
  team_name: string;
  access_type: 'PRIVATE' | 'PUBLIC';
  validation_limit: number;
}

interface DeleteValidationProps {
  target: string | number;
}

interface GetValidationsRequest extends Action<typeof GET_VALIDATIONS_REQUEST> { }
interface GetValidationsSuccess extends Action<typeof GET_VALIDATIONS_SUCCESS> {
  payload: Validation[]
}
interface GetValidationsFailure extends Action<typeof GET_VALIDATIONS_FAILURE> {
  payload: string
}

interface GetValidationRequest extends Action<typeof GET_VALIDATION_REQUEST> { }
interface GetValidationSuccess extends Action<typeof GET_VALIDATION_SUCCESS> {
  payload: Validation
}
interface GetValidationFailure extends Action<typeof GET_VALIDATION_FAILURE> {
  payload: string
}

interface AddValidationRequest extends Action<typeof ADD_VALIDATION_REQUEST> { }
interface AddValidationSuccess extends Action<typeof ADD_VALIDATION_SUCCESS> { }
interface AddValidationFailure extends Action<typeof ADD_VALIDATION_FAILURE> {
  payload: string
}

interface EditValidationRequest extends Action<typeof EDIT_VALIDATION_REQUEST> { }
interface EditValidationSuccess extends Action<typeof EDIT_VALIDATION_SUCCESS> { }
interface EditValidationFailure extends Action<typeof EDIT_VALIDATION_FAILURE> {
  payload: string
}

interface DeleteValidationRequest extends Action<typeof DELETE_VALIDATION_REQUEST> { }
interface DeleteValidationSuccess extends Action<typeof DELETE_VALIDATION_SUCCESS> { }
interface DeleteValidationFailure extends Action<typeof DELETE_VALIDATION_FAILURE> {
  payload: string
}

export type ActionTypes = GetValidationsRequest | GetValidationsSuccess | GetValidationsFailure
  | GetValidationRequest | GetValidationSuccess | GetValidationFailure
  | AddValidationRequest | AddValidationSuccess | AddValidationFailure
  | EditValidationRequest | EditValidationSuccess | EditValidationFailure
  | DeleteValidationRequest | DeleteValidationSuccess | DeleteValidationFailure

// Action creators
const getValidationsRequest = (): GetValidationsRequest => ({ type: GET_VALIDATIONS_REQUEST });
const getValidationsSuccess = (data: Validation[]): GetValidationsSuccess => ({ type: GET_VALIDATIONS_SUCCESS, payload: data });
const getValidationsFailure = (error: string): GetValidationsFailure => ({ type: GET_VALIDATIONS_FAILURE, payload: error });

const getValidationRequest = (): GetValidationRequest => ({ type: GET_VALIDATION_REQUEST });
const getValidationSuccess = (data: Validation): GetValidationSuccess => ({ type: GET_VALIDATION_SUCCESS, payload: data });
const getValidationFailure = (error: string): GetValidationFailure => ({ type: GET_VALIDATION_FAILURE, payload: error });

const addValidationRequest = (): AddValidationRequest => ({ type: ADD_VALIDATION_REQUEST });
const addValidationSuccess = (): AddValidationSuccess => ({ type: ADD_VALIDATION_SUCCESS });
const addValidationFailure = (error: string): AddValidationFailure => ({ type: ADD_VALIDATION_FAILURE, payload: error });

const editValidationRequest = (): EditValidationRequest => ({ type: EDIT_VALIDATION_REQUEST });
const editValidationSuccess = (): EditValidationSuccess => ({ type: EDIT_VALIDATION_SUCCESS });
const editValidationFailure = (error: string): EditValidationFailure => ({ type: EDIT_VALIDATION_FAILURE, payload: error });

const deleteValidationRequest = (): DeleteValidationRequest => ({ type: DELETE_VALIDATION_REQUEST });
const deleteValidationSuccess = (): DeleteValidationSuccess => ({ type: DELETE_VALIDATION_SUCCESS });
const deleteValidationFailure = (error: string): DeleteValidationFailure => ({ type: DELETE_VALIDATION_FAILURE, payload: error });

interface QueryProps {
  pages?:Number;
  rows?: 5 | 10 | 25
}
// Async action creators
const getValidations = (page?:number , rows: QueryProps['rows'] = 10, params:any = null) => {
  const token = window.localStorage.getItem('token')

  let queryOptions: QueryProps = {
    rows: 10
  }
  if(page != undefined){
    queryOptions.pages= page
  }
  if(rows !== undefined){
    queryOptions.rows =rows
  }

if(params){
  queryOptions = Object.assign({}, queryOptions, params);
}


  let query = ''
  Object.keys(queryOptions)?.map((key , i)=>{
    if(i === 0){
      query = `?${key}=${queryOptions[key]}`
    }else{
      query = query + `&${key}=${queryOptions[key]}`
    }
  })


  return async (dispatch: Dispatch) => {
    try {
      dispatch(getValidationsRequest());
      const response = await axios.get(
        `/validations/all${query}`
        , {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      dispatch(getValidationsSuccess(response.data || {}))

    } catch (error: any) {
      console.log('error', error)
      NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
      dispatch(getValidationsFailure(error.message));
    }
  };
};

const getIndividualReport =(uuid: string) => {
  const token = window.localStorage.getItem('token')
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getValidationRequest());
      const response = await axios.get(
        `/validations/${uuid}/iv`
        , {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      let filteredArticles = [];
      if (response.data && response.data.report && response.data.report.articles ){
         filteredArticles = response.data.report.articles.filter((article: any) => {
          const idmPercent = parseInt(article.idm_percent, 10); 
              return idmPercent > 60; 
            });
      }

      dispatch(getValidationSuccess({ ...response.data, report: { ...response.data.report, articles: filteredArticles } }));

    } catch (error: any) {
      console.log('error', error)
      NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
      dispatch(getValidationFailure(error.message));
    }
  };
}

const getLEVReport =(uuid: string) => {
  const token = window.localStorage.getItem('token')
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getValidationRequest());
      const response = await axios.get(
        `/validations/${uuid}/lev`
        , {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      let filteredArticles = [];
      if (response.data && response.data.report && response.data.report.articles ){
       filteredArticles = response.data.report.articles.filter((article: any) => {
        const idmPercent = parseInt(article.legal_entity_idm_percent, 10); 
            return idmPercent > 60; 
          });
      }

      dispatch(getValidationSuccess({ ...response.data, report: { ...response.data.report, articles: filteredArticles } }));

    } catch (error: any) {
      console.log('error', error)
      NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
      dispatch(getValidationFailure(error.message));
    }
  };
}

// const getValidation = (uuid: string) => {
//   const token = window.localStorage.getItem('token')
//   return async (dispatch: Dispatch) => {
//     try {
//       dispatch(getValidationRequest());
//       const response = await axios.get(
//         `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams/${uuid}/team`
//         , {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       )
//       dispatch(getValidationSuccess(response.data?.team || {}))

//     } catch (error: any) {
//       console.log('error', error)
//       NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
//       dispatch(getValidationFailure(error.message));
//     }
//   };
// };

// const addValidation = (data: AddValidationProps, resetCloseForm: () => void): ThunkAction<Promise<void>, any, any, ActionTypes> => {
//   const token = window.localStorage.getItem('token');
//   return async (dispatch: ThunkDispatch<any, any, ActionTypes>) => {
//     try {
//       dispatch(addValidationRequest());
//       await axios.post(
//         // `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams`
//         `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams`
//         ,
//         { ...data },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       dispatch(addValidationSuccess());
//       dispatch(getValidations()); // Call the getValidations action after adding a team
//       resetCloseForm();
//     } catch (error: any) {
//       console.log('error', error);
//       NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
//       dispatch(addValidationFailure(error.message));
//     }
//   };
// };

// const editValidation = (data: AddValidationProps, id: number, resetCloseForm: () => void): ThunkAction<Promise<void>, any, any, ActionTypes> => {
//   const token = window.localStorage.getItem('token');
//   return async (dispatch: ThunkDispatch<any, any, ActionTypes>) => {
//     try {
//       dispatch(editValidationRequest());
//       await axios.put(
//         // `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams/${id}`
//         `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams/${id}`
//         ,
//         { ...data },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       dispatch(editValidationSuccess());
//       dispatch(getValidations()); // Call the getValidations action after adding a team
//       resetCloseForm();
//     } catch (error: any) {
//       console.log('error', error);
//       NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
//       dispatch(editValidationFailure(error.message));
//     }
//   };
// };

// const deleteValidationWithMove = (id: number, data: DeleteValidationProps, resetCloseForm: () => void): ThunkAction<Promise<void>, any, any, ActionTypes> => {
//   const token = window.localStorage.getItem('token');
//   return async (dispatch: ThunkDispatch<any, any, ActionTypes>) => {
//     try {
//       dispatch(deleteValidationRequest());
//       await axios.put(
//         // `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams/${id}`
//         `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams/${id}/delete-and-move-team`,
//           data
//         ,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       dispatch(deleteValidationSuccess());
//       dispatch(getValidations()); // Call the getValidations action after adding a team
//       resetCloseForm();
//     } catch (error: any) {
//       console.log('error', error);
//       NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
//       dispatch(deleteValidationFailure(error.message));
//     }
//   };
// };

// const permanentlyDeleteValidation = ( id: number, resetCloseForm: () => void): ThunkAction<Promise<void>, any, any, ActionTypes> => {
//   const token = window.localStorage.getItem('token');
//   return async (dispatch: ThunkDispatch<any, any, ActionTypes>) => {
//     try {
//       dispatch(deleteValidationRequest());
//       await axios.delete(
//         // `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams/${id}`
//         `${process.env.REACT_APP_NODE_SERVER_URL}/app/teams/${id}`
//         ,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       dispatch(deleteValidationSuccess());
//       dispatch(getValidations()); // Call the getValidations action after deleteing a team
//       resetCloseForm();
//     } catch (error: any) {
//       console.log('error', error);
//       NotificationManager.error(error?.response?.data?.message || error?.message || 'Something went wrong')
//       dispatch(deleteValidationFailure(error.message));
//     }
//   };
// };


const actions = {
  getValidations,
  getIndividualReport,
  getLEVReport
//   addValidation,
//   editValidation,
//   getValidation,
//   deleteValidationWithMove,
//   permanentlyDeleteValidation
}

export default actions
