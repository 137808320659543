import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import * as moment from 'moment';
import 'moment/locale/fr';
import EN from './locales/en';
import FR from './locales/fr';

i18n
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next
  .use(initReactI18next)
  // initialize with options
  .init({
    fallbackLng: 'en',
    debug:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    ns: ['glossary', 'validation', 'sentences'],
    defaultNS: 'glossary',

    saveMissing: true,

    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'span'],
    },

    resources: {
      en: {
        glossary: EN.glossary,
        sentences: EN.sentences,
        validation: EN.validation,
      },
      fr: {
        glossary: FR.glossary,
        sentences: FR.sentences,
        validation: FR.validation,
      },
    },
  });

moment.locale(i18n.language);

export default i18n;

export const toggleLanguage = language => {
  if (i18n.language != null && i18n.language.includes('fr')) {
    i18n.changeLanguage('en');
    moment.locale('en');
  } else {
    i18n.changeLanguage('fr');
    moment.locale('fr');
  }

  window.location.reload();
};

export const toTitleCase = phrase => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const toFamilyCase = phrase => {
  return phrase
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('-');
};
