// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { PRIVATE, ADMIN } from 'router/routes';
import { useUser } from 'utils/contexts/app-context';
import { useFlags } from 'providers/FlagsProvider';
import FeatureFlags from 'utils/feature-flags';
import logo from '../../assets/images/logo-valital.jpg';
import PageSelector from './page-selector';
import styles from './styles.module.scss';

import ProfilePictureWithDropdown from './profile-picture-with-dropdown';
import Hamburger from './hamburger';
import SearchBar from './search-bar';
import MobileNavAnimator from './mobile-nav-animator';

type Props = {
  setMobileMenuOpen: (isOpen: ?boolean) => void,
};

/**
 * Component representing the sticky navbar
 */
const NavBar = (props: Props) => {
  const [isSearchExpanded, setSearchExpanded] = React.useState(null);
  const [searchBarHasFocus, setSearchBarHasFocus] = React.useState(null);
  const user = useUser();
  const { t } = useTranslation();
  const { matchedFlags } = useFlags({
    authorizedFlags: [
      FeatureFlags.AllowDelegates,
      FeatureFlags.AllowDashboard,
      FeatureFlags.AllowTalents,
      FeatureFlags.AllowValidations,
    ],
  });

  const pages = [
    {
      name: t('nouns.dashboard'),
      route: PRIVATE.dashboard,
      flag: FeatureFlags.AllowDashboard,
    },
    {
      name: t('nouns.talent_plural'),
      route: PRIVATE.talents,
      flag: FeatureFlags.AllowTalents,
    },
  ];

  const userType = _.get(user, ['employments', '0', 'user_type']);
  const pagesToShow = pages.filter(page => {
    const inMatchedFlags = matchedFlags.includes(page.flag);

    if (page.route === ADMIN.delegates) {
      return userType === 'ADMIN' && inMatchedFlags;
    }

    return inMatchedFlags;
  });

  const onHamburgerClick = React.useCallback(() => {
    if (isSearchExpanded) {
      setSearchExpanded(false);
    } else {
      props.setMobileMenuOpen(true);
    }
  }, [isSearchExpanded, setSearchExpanded, window]);

  const onSearchClick = React.useCallback(() => {
    if (!isSearchExpanded) {
      setSearchExpanded(true);
    }
  }, [isSearchExpanded, setSearchExpanded]);

  const getLogo = () => logo;

  return (
    <div>
      {/* Desktop content */}
      <div className={styles.desktop_content}>
        <Link to={PRIVATE.dashboard}>
          <img className={styles.logo} src={getLogo()} alt="" />
        </Link>

        <SearchBar count={5} />

        <PageSelector pages={pagesToShow} />

        <ProfilePictureWithDropdown picture={user ? user.picture : null} />
      </div>

      {/* Mobile content */}
      <div className={styles.mobile_content}>
        <MobileNavAnimator open={isSearchExpanded}>
          {(translationStyle, opacityStyle, shouldDisplayInput, rawRatio) => (
            <React.Fragment>
              <div
                className={styles.mobile_logo_holder}
                style={translationStyle}
              >
                <Link to={PRIVATE.dashboard}>
                  <img className={styles.logo} src={getLogo()} alt="" />
                </Link>
              </div>

              <div className={styles.mobile_right_content}>
                <div
                  className={`${styles.mobile_search_holder} ${
                    isSearchExpanded ? 'clicked' : ''
                  }`}
                  style={{ zIndex: searchBarHasFocus ? 1000 : 0 }}
                  onClick={onSearchClick}
                >
                  <SearchBar
                    count={5}
                    isMobileBarOpen={isSearchExpanded}
                    onFocus={() => setSearchBarHasFocus(true)}
                    onBlur={() => setSearchBarHasFocus(false)}
                  />
                </div>
                <div className={styles.mobile_rightmost_content}>
                  {!shouldDisplayInput && (
                    <div
                      className={styles.mobile_bell_holder}
                      style={opacityStyle}
                    >
                    </div>
                  )}

                  <div
                    className={styles.mobile_hamburger_holder}
                    onClick={onHamburgerClick}
                  >
                    <Hamburger animationProgress={rawRatio} />
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </MobileNavAnimator>
      </div>
    </div>
  );
};

export default NavBar;
